// ===========================================================
// Video Grid
// ===========================================================

.m-image-video-grid {
    .container {
        @include grid-row;
        @include grid-single-col;

        @include media(">=medium") {
            @include grid-single-col-wide;

            &.wide {
                @include grid-single-col-full;
            }
        }
    }

    .content-block-wrapper {
        display: flex;
        flex-flow: row wrap;
        row-gap: rem-calc(45);
        column-gap: rem-calc(16);
        width: 100%;
        max-width: rem-calc(1179);
        margin: 0 auto;

        @include media(">=large") {
            column-gap: rem-calc(32);
        }
    }

    .image-video-grid-heading {
        text-align: center;
        margin-bottom: rem-calc(20);
    }

    .description-section {
        p {
            margin: rem-calc(17 0 12);
        }
    }

    .block {
        width: 100%;

        button,
        .image-wrapper {
            @include aspect-ratio(572, 309);
            width: 100%;
            margin-bottom: rem-calc(24);
        }

        .h6 {
            color: $palette-primary;
        }

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .two-columns {
        &.regular {
            button,
            .image-wrapper {
                @include aspect-ratio(448, 363);
            }
        }
        &.wide {
            button,
            .image-wrapper {
                @include aspect-ratio(544, 366);
            }
        }
    }

    .three-columns {
        button,
        .image-wrapper {
            @include aspect-ratio(352, 432);
        }
    }

    .four-columns {
        button,
        .image-wrapper {
            @include aspect-ratio(256, 312);
        }
    }

    .video-block {
        button {
            @extend .button-style-none;
            transition: transform $global-transition;

            > * {
                pointer-events: none;
            }

            &:hover {
                transform: scale(1.05);

                .svg-wrapper {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }

            &:focus {
                .svg-wrapper {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }
        .video-wrapper {
            .svg-wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: rem-calc(67);
                height: rem-calc(67);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba($palette-text, 0.7);
                box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.119919);
                border: rem-calc(2) solid $white;
                transition: transform $global-transition;
            }
            svg {
                width: rem-calc(25);
                height: rem-calc(25);
                margin-left: rem-calc(7);
                fill: $white;
            }
        }
    }

    @include media(">=medium") {
        .image-video-grid-heading {
            margin-bottom: rem-calc(37);
        }

        .content-block-wrapper {
            row-gap: rem-calc(60);
        }

        .block {
            width: calc(50% - 8px);

            .h5 {
                font-size: rem-calc(20);
            }
        }

        .three-columns,
        .four-columns {
            .block {
                width: calc((100% - 32px) / 3);
            }
        }
    }

    @include media(">=large") {
        .block {
            width: calc(50% - 16px);
        }
        .three-columns .block {
            width: calc((100% - 64px) / 3);
        }

        .four-columns .block {
            width: calc((100% - 96px) / 4);
        }
    }
}

.video-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    opacity: 0;
    transition: opacity 300ms ease;

    .video-panel-background {
        width: 100%;
        height: 100%;
        background: rgba($black, 0.75);
    }

    .video-container {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: auto;
        max-height: 75vh;

        iframe {
            max-height: 75vh;
        }

        &:focus {
            outline: none;
        }
    }

    .close-button {
        position: fixed;
        top: -40px;
        right: -40px;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        background: $white;
        transition: transform 300ms ease;

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: " ";
            height: 20px;
            width: 1px;
            background-color: $palette-primary;
            transition: background-color 300ms ease;
        }
        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        &:hover,
        &:focus {
            transform: scale(1.1);
            &::before,
            &::after {
                background-color: $palette-text;
            }
        }

        @include media(">=large") {
            top: -70px;
            right: -70px;
            width: 60px;
            height: 60px;

            &::before,
            &::after {
                height: 30px;
            }
        }
    }

    &[data-visible="true"] {
        opacity: 1;
    }
}
