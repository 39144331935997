.m-three-up-cards {
    padding-top: rem-calc(92);

    &:last-child {
        padding-bottom: 0;
    }
    .heading-container {
        @include line-decoration;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: rem-calc(42);

        .heading-eyebrow {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(12);
            color: $palette-text;
        }

        @include media(">=large") {
            margin-bottom: rem-calc(63);
        }
    }
    .three-up-cards-container {
        @include grid-row;
        @include grid-columns(3);
        grid-gap: 0;
        width: 100vw;

        .card {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: rem-calc(56 24 24);
            min-height: rem-calc(250);
            height: 25vh;
            width: 100%;
            overflow: hidden;
            text-decoration: none;

            .h4 {
                margin-bottom: rem-calc(7);
                color: $palette-secondary-light-text;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: transform 4s ease-out;
                transform-origin: center;
                z-index: -1;
            }

            .card-text-content {
                width: 100%;
                text-align: center;
                z-index: 1;

                p {
                    font: {
                        family: $typeface-alt-header;
                        size: rem-calc(24);
                    }
                    line-height: 0.5;
                    color: $white;
                }

                .button {
                    margin-top: rem-calc(20);
                    font-size: rem-calc(22);

                    &:hover,
                    &:focus {
                        color: $palette-text;
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(black, 0.45);
                z-index: 0;
                @include media(">=medium") {
                    background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.55) 28.5%,
                        rgba(0, 0, 0, 0) 146.5%
                    );
                    mix-blend-mode: multiply;
                }
            }

            &:focus,
            &:hover {
                img {
                    transition: transform 6s ease-out;
                    transform: scale(1.1);
                }
                .button {
                    svg {
                        path {
                            /* clean-css ignore:start */
                            d: path(
                                "M0,5.2h23.3v1.5H0V5.2z M19.4,0l5.3,5.5l-1.1,1l-5.3-5.4L19.4,0z M18,10.9l6-6.1l1,1L19,12L18,10.9z"
                            );
                            /* clean-css ignore:end */
                        }
                    }
                }
            }

            &.js-video-hover-play {
                img {
                    display: block;
                }

                .video-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    z-index: -1;
                }

                &:focus,
                &:hover {
                    img {
                        display: none;
                    }
                }
            }
        }

        @include media(">=medium") {
            @include grid-three-symmetric-cols;
            grid-gap: 0;

            .card {
                justify-content: flex-start;
                height: rem-calc(400);

                .card-text-content {
                    text-align: left;

                    .button {
                        margin-top: rem-calc(24);
                    }
                }
            }
        }

        @include media(">=large") {
            .card {
                padding: rem-calc(62 42 32 42);
                height: rem-calc(500);

                .card-text-content {
                    p {
                        font-size: rem-calc(28);
                    }
                }
            }
        }

        @include media(">=xlarge") {
            .card {
                padding: rem-calc(72 72 32 72);
                height: rem-calc(600);
            }
        }

        @include media(">=xxlarge") {
            max-width: rem-calc(2000);
            margin: 0 auto;
        }
    }

    &:last-child {
        padding-bottom: 0;
    }
}
