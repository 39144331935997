.subscribe-form {
    flex: 100%;
    outline: rem-calc(1) solid $palette-primary;
    overflow: hidden;

    #mce-responses {
        position: absolute;
        right: 0;
        bottom: -38px;

        .response {
            outline: 1px solid $palette-primary;
            padding: 9px;
        }
    }

    .input-wrapper {
        position: relative;
        display: flex;
        background-color: $palette-background-default;
    }
    .input-label {
        width: 100%;
    }
    .input-label,
    button {
        height: rem-calc(72);
    }
    .label-text {
        display: block;
        position: absolute;
        left: 2rem;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0;
        font: {
            family: $typeface-alt-header;
            size: rem-calc(18);
        }
        letter-spacing: 0.155em;
        color: $palette-dark-emphasis;
        transition: all 200ms ease;
        transform-origin: left;
    }
    .input-label {
        &.has-value {
            .label-text {
                transform: scale(0.8) translate(1px, -35px);
                transform-origin: left;
            }
        }
        &:focus-within {
            .label-text {
                color: $palette-primary-darker;
            }
        }
    }
    [type="email"] {
        height: 100%;
        margin: 0;
        padding: rem-calc(37 32 20);
        border: 0;
        color: $palette-light-text;
        background-image: none;
        font-size: rem-calc(16);
        transition: background-color $global-transition,
            color $global-transition;

        &:focus {
            background-color: $white;
            color: $palette-dark-text;
            border: 0;
        }
    }

    .button[type="submit"] {
        @extend .button;
        font-size: rem-calc(18);
        background-color: $palette-background-med;
        color: $palette-light-text;
        border-color: $palette-primary;
        border: 0;
        min-width: rem-calc(213);
        height: rem-calc(72);
        padding: {
            left: rem-calc(44);
            right: rem-calc(50);
        }
    }

    @include media("<small") {
        outline: none;
        border-radius: 0;
        overflow: visible;

        #mce-responses {
            width: 100%;
        }

        .input-wrapper {
            flex-flow: row wrap;
            row-gap: rem-calc(8);
            background: transparent;
        }
        .input-label {
            flex: 100%;
            .label-text {
                transform-origin: center;
            }

            &.has-value {
                .label-text {
                    transform-origin: center;
                    transform: scale(0.8) translate(-62.75%, -35px);
                }
            }
        }
        [type="email"] {
            text-align: center;
            background-color: $palette-background-default;
            outline: rem-calc(1) solid $palette-primary;
            padding: {
                left: rem-calc(25);
                right: rem-calc(25);
            }
        }
        .label-text {
            text-align: center;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $palette-dark-emphasis;
        }
        button {
            width: 100%;
        }
    }

    @include media(">=520px") {
        min-width: rem-calc(450);
    }
}
