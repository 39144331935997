// ===========================================================
// Accordion
// ===========================================================

.accordion-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	.plus {
		background-color: $palette-link;
		height: 22px;
		width: 2px;
		transition: transform $global-transition;
		&:nth-child(2) {
			transform: rotate(90deg) translate(0, 2px);
		}
	}
	&[data-toggle="open"] {
		.plus:nth-child(1) {
			transform: rotate(90deg) translate(0, 2px);
		}
	}
}

.accordion-content {
	z-index: -1;
	opacity: 0;
	max-height: 0;
	transform: scale(1, 0);
	transform-origin: top;
	transition: transform 450ms ease, opacity 450ms ease,
		max-height 400ms ease-in;
	&[aria-hidden="false"] {
		transform: scale(1, 1);
		opacity: 1;
		max-height: 100vh;
		z-index: 1;
	}
}
