// ===========================================================
// Social
// ===========================================================

.nav-social {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;

    .heading-eyebrow {
        display: block;
        flex-basis: 100%;
        margin-bottom: rem-calc(18);
        font: {
            family: $typeface-global;
            size: rem-calc(18);
        }
    }
    li {
        flex: 0 0 rem-calc(32);
        padding-bottom: 0;
        + li {
            margin-left: rem-calc(32);
        }
        &::before {
            content: none;
        }
        a {
            display: flex;
            svg {
                fill: $palette-primary;
                width: rem-calc(32);
                height: rem-calc(32);
                transition: transform 200ms ease-in, opacity 200ms ease-in;
            }
            &:hover,
            &:focus {
                outline: 0;
                svg {
                    fill: $palette-primary-lighter;
                    transform: translateY(-3px);
                }
            }
        }
    }

    @include media(">=medium") {
        li {
            flex: 0 0 rem-calc(16);
            + li {
                margin-left: rem-calc(16);
            }
            a {
                svg {
                    width: rem-calc(24);
                    height: rem-calc(24);
                }
            }
        }
    }
}
