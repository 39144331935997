// ===========================================================
// Card
// ===========================================================

.card {
    width: rem-calc(352);
    max-width: 100vw;
    height: auto;
    min-height: 100%;
    text-align: left;

    .card-content {
        background-color: $white;
        display: flex;
        flex-direction: column;
    }

    .card-text-container {
        height: auto;
        padding: rem-calc(32 32 36);

        p + .button {
            margin-top: rem-calc(7);
        }

        .heading-eyebrow {
            margin-bottom: rem-calc(15);
            span {
                margin: rem-calc(0 5);
                color: $palette-secondary;
            }
        }
    }

    .button-group {
        justify-content: flex-start;
    }

    .card-image-container {
        position: relative;
        @include aspect-ratio(352, 214);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-vertical {
        margin-right: rem-calc(16);

        @include media(">=medium") {
            margin-right: rem-calc(32);

            .card-text-container {
                min-height: rem-calc(242);
            }
        }

        &.large {
            width: rem-calc(375);

            .card-image-container {
                @include aspect-ratio(375, 348);
            }
            .card-text-container {
                padding: rem-calc(32);
            }

            @include media(">=medium") {
                width: rem-calc(469);
                margin-right: rem-calc(16);

                .card-image-container {
                    @include aspect-ratio(469, 348);
                }

                .card-text-container {
                    padding: rem-calc(32 45 35 56);
                    min-height: rem-calc(292);
                }
            }
        }
    }

    &-horizontal {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: rem-calc(32);
        }

        // stay vertical on mobile
        @include media(">=medium") {
            .card-content {
                flex-direction: row;
            }
            &.is-reversed {
                .card-content {
                    flex-direction: row-reverse;
                }
            }
            .card-image-container {
                flex: 0 0 35%;
                &::before {
                    height: 100%;
                }
            }
            .card-text-container {
                padding: rem-calc(46 76);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &:not(:last-child) {
                margin-bottom: rem-calc(56);
            }
        }

        @include media(">=large") {
            .card-content {
                min-height: rem-calc(524);
            }

            .card-text-container {
                padding: rem-calc(56 96);

                .heading-eyebrow {
                    margin-bottom: rem-calc(17);
                }

                .h4 {
                    margin-bottom: rem-calc(17);
                }
            }
        }
    }
}
