// ===========================================================
// Contact Form 7 Styles
// ===========================================================

.wpcf7 {
  // Screen Readers
  .screen-reader-response {
    border: 0;
  }

  // Loader
  .ajax-loader {
    visibility: hidden;
    display: inline-block;
    background-image: url("../images/loader.png");
    background-size: 38px 38px;
    width: rem-calc(38);
    height: rem-calc(38);
    border: none;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: 0.8;
    position: relative;
    right: rem-calc(-20);
    &.is-active {
      visibility: visible;
    }
  }

  .ajax-error {
    display: none;
  }

  .wpcf7-submit:disabled {
    cursor: not-allowed;
  }
}

.wpcf7-mail-sent-ok,
.wpcf7-mail-sent-ng,
.wpcf7-aborted,
.wpcf7-response-output,
.wpcf7-spam-blocked,
.wpcf7-validation-errors,
.wpcf7-acceptance-missing,
span.wpcf7-not-valid-tip {
  border: 0;
  padding: 0;
  margin: 0;
  color: $palette-error;
  font-size: rem-calc(12);
  line-height: 1.58;
  letter-spacing: rem-calc(0.9);
  margin-top: rem-calc(16);
}

span.wpcf7-not-valid-tip {
  display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 100;
}

.wpcf7-acceptance-missing,
.wpcf7-spam-blocked {
  color: $palette-warning;
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label {
  &::before,
  &::after {
    content: " ";
  }
}

.wpcf7-display-none {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
