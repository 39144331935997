// ===========================================================
// Grids
// ===========================================================

// Using Named Grid areas for grids. Each Module uses the same content area names, col-1, col-2 etc. This means we can hotswap grids into modules.

// Row and Cols Defaults
@mixin grid-row {
    display: grid;
    grid-row-gap: $grid-gap-horizontal;
    grid-template-columns: 1fr;
    @include media(">=medium") {
        grid-row-gap: 0;
        grid-column-gap: $grid-gap-vertical;
    }
}

// Single Col
@mixin grid-single-col {
    @include media(">=medium") {
        .grid-col {
            grid-column: colcentered;
        }
    }
}

// Multi Col - Defaults to 2
@mixin grid-columns($length: 2) {
    @include media(">=medium") {
        .grid-col {
            @for $i from 1 through $length {
                &:nth-child(#{$i}n) {
                    grid-column: col#{$i};
                }
            }
        }
    }
}

// Multi Col - Reverse Order Defaults to 2
@mixin grid-columns-reversed($length: 2) {
    @include media(">=medium") {
        .grid-col {
            grid-row: 1;
            &:nth-child(1) {
                grid-column: col2;
            }
            &:nth-child(2) {
                grid-column: col1;
            }
        }
    }
}

// --------------- Single Cel ---------------

// 1 Cell, 10 Cols wide
@mixin grid-single-col-wide {
    grid-template-columns: 1fr [colcentered-start] repeat(10, 1fr) [colcentered-end] 1fr;
}

@mixin grid-single-col-full {
    grid-template-columns: [colcentered-start] repeat(12, 1fr) [colcentered-end];
}

@mixin grid-full-width-offset {
    grid-template-columns: 1fr [colcentered-start] repeat(11, 1fr) [colcentered-end];
}

// 1 Cell, 8 Cols Wide
@mixin grid-single-col-medium {
    grid-template-columns: repeat(2, 1fr) [colcentered-start] repeat(8, 1fr) [colcentered-end] repeat(
            2,
            1fr
        );
}

// 1 Cell, 6 Cols Wide
@mixin grid-single-col-thin {
    grid-template-columns: repeat(3, 1fr) [colcentered-start] repeat(6, 1fr) [colcentered-end] repeat(
            3,
            1fr
        );
}

// --------------- Double Column---------------
// 2 Symmetric Columns
// 6 Cols wide
@mixin grid-two-symmetric-cols {
    grid-template-columns:
        [col1-start] repeat(6, 1fr) [col1-end col2-start] repeat(6, 1fr)
        [col2-end];
}

// 2 Symmetric Columns
// 5 Cols wide with 1 col margin
@mixin grid-two-symmetric-cols-padded {
    grid-template-columns:
        1fr [col1-start] repeat(5, 1fr) [col1-end col2-start] repeat(5, 1fr)
        [col2-end] 1fr;
}

// 2 Symmetric Columns
// 4 Cols wide with 1 col margin
@mixin grid-two-symmetric-cols-double-padded {
    grid-template-columns:
        repeat(2, 1fr) [col1-start] repeat(4, 1fr) [col1-end col2-start] repeat(
            4,
            1fr
        )
        [col2-end] repeat(2, 1fr);
}

// 2 Asymmetric Columns
// 5 cols / 7cols
@mixin grid-two-asymmetric-cols {
    grid-template-columns:
        [col1-start] repeat(5, 1fr) [col1-end col2-start] repeat(7, 1fr)
        [col2-end];
}

// 2 Asymmetric Columns Reversed
// 7 cols / 5cols
@mixin grid-two-asymmetric-cols-reversed {
    grid-template-columns:
        [col1-start] repeat(7, 1fr) [col1-end col2-start] repeat(5, 1fr)
        [col2-end];
}

// 2 Asymmetric Columns Padded
// 1 / 4 / 6 / 1
@mixin grid-two-asymmetric-cols-padded {
    grid-template-columns:
        1fr [col1-start] repeat(4, 1fr) [col1-end col2-start] repeat(6, 1fr)
        [col2-end] 1fr;
}

// 2 Asymmetric Columns Spaces
@mixin grid-two-asymmetric-cols-spaced {
    grid-template-columns:
        [col1-start] repeat(5, 1fr) [col1-end] 1fr [col2-start] repeat(6, 1fr)
        [col2-end] 1fr;
}

// --------------- Triple Column---------------

// 3 Symmetric Columns
// 4 Cols wide on Tablet
@mixin grid-three-symmetric-cols {
    grid-template-columns:
        [col1-start] repeat(4, 1fr) [col1-end col2-start] repeat(4, 1fr)
        [col2-end col3-start] repeat(4, 1fr) [col3-end];
}

// 3 Asymmetric Columns Padded
// 1 / 4 / 3 / 3 / 1
@mixin grid-three-asymmetric-cols-padded {
    grid-template-columns:
        1fr [col1-start] repeat(4, 1fr) [col1-end col2-start] repeat(3, 1fr)
        [col2-end col3-start] repeat(3, 1fr) [col3-end] 1fr;
}

// --------------- Quad Column---------------

// 4 Symmetric Columns
// 3 Cols wide on Tablet
@mixin grid-four-symmetric-cols {
    grid-template-columns:
        [col1-start] repeat(3, 1fr) [col1-end col2-start] repeat(3, 1fr)
        [col2-end col3-start] repeat(3, 1fr) [col3-end col4-start] repeat(
            3,
            1fr
        )
        [col4-end];
}
