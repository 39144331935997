.m-accordion {
    .container {
        @include grid-row;
        @include grid-single-col;

        .grid-col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .accordion-row {
        background-color: transparent;
        transition: background-color $global-transition,
            box-shadow $global-transition;
        &:not(:last-child) {
            border-bottom: rem-calc(1) solid $palette-primary-lighter;
        }
        &.accordion-row-open {
            background-color: transparent;
            box-shadow: inset 0px 0px 0px 2px $palette-primary-lighter;
        }
    }

    h2 {
        color: $palette-text;
        margin-bottom: rem-calc(60);
    }

    .accordion-header-button {
        @extend .button-style-none;
        display: flex;
        width: 100%;
        align-items: center;
        user-select: none;
        cursor: pointer;
        padding: rem-calc(20 50 20 35);
        position: relative;

        &:focus-visible {
            outline: 5px auto -webkit-focus-ring-color;
        }

        .plus {
            width: rem-calc(16);
            height: rem-calc(16);
            display: block;
            position: absolute;
            right: rem-calc(24);
            transition: all 300ms ease;

            .line {
                display: block;
                width: rem-calc(16);
                height: rem-calc(1);
                background-color: $palette-dark;
                transition: all 250ms ease;
                transform-origin: center center;
                position: absolute;
                top: 50%;
                left: 0;

                &:nth-child(2n) {
                    transform: rotate(90deg);
                }
            }
        }

        &.accordion-open {
            .plus {
                transform: rotate(45deg);
            }
        }
    }

    .accordion-header {
        font-family: $typeface-alt-header;
        // color: $palette-text;
        text-align: left;
        margin: 0;
    }

    .accordion {
        display: block;
        width: 100%;
        border: rem-calc(1) solid $palette-primary-lighter;
    }

    .accordion-content {
        display: none;
        overflow-y: hidden;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        text-align: left;

        .accordion-content-inner,
        .accordion-buttons {
            padding: rem-calc(0 50 20 35);
            opacity: 0;
            transition: opacity 250ms ease;
        }

        .accordion-buttons {
            justify-content: flex-start;
            margin: {
                bottom: rem-calc(8);
                top: 0;
            }
            .button:first-child {
                margin-top: 0;
            }
        }

        &[aria-hidden="false"] {
            max-height: 100vh;
            transition: max-height 1s ease-in-out;

            .accordion-content-inner,
            .accordion-buttons {
                opacity: 1;
                transition-delay: 100ms;
            }
        }
    }

    &.section-container {
        &:first-child {
            padding-top: rem-calc(90);
        }
    }

    @include media(">=medium") {
        .container {
            @include grid-single-col-wide;
        }

        .accordion-header-button {
            padding: rem-calc(24 80 24 48);
        }

        .accordion-content {
            .accordion-content-inner,
            .accordion-buttons {
                padding: rem-calc(0 80 24 48);
            }
        }
    }

    @include media(">=large") {
        &.section-container {
            &:first-child {
                padding-top: rem-calc(140);
            }
        }

        h2 {
            margin-bottom: rem-calc(88);
        }

        .container {
            @include grid-single-col-medium;
        }
    }
}
