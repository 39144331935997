// ===========================================================
// Buttons
// ===========================================================

$button-spacing: rem-calc(12);
$button-focus: solid rem-calc(2) rgba($palette-text, 0.12);

// .button classes can be added to any <a>, <input> or <button> element
%button {
    appearance: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc(2 30 0);
    height: rem-calc(72);
    min-width: rem-calc(214);
    background-color: $palette-background-med;
    border: solid rem-calc(1) $palette-background-med;
    // remove if you add a proper border radius to buttons, this is to prevent iOS from adding a border radius on it's mobile devices for inputs
    -webkit-border-radius: 0px;
    color: $palette-light-text;
    @include font-size($button-font);
    font-family: $typeface-alt-header;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-decoration: none;
    font-weight: 400;
    transition: color $global-transition, background-color $global-transition,
        border-color $global-transition;

    .arrow-wrap {
        position: relative;
        margin: {
            bottom: rem-calc(2);
            left: rem-calc(5);
        }
        height: rem-calc(12);
        width: rem-calc(25);
        color: $palette-dark-text;
        transition: color $global-transition;

        svg {
            position: absolute;
            left: rem-calc(-4);
            height: rem-calc(12);
            width: rem-calc(32);

            path {
                transition: all 200ms ease;
            }
        }
    }

    &:hover,
    &:focus {
        background-color: $palette-background-default;
        border-color: $palette-background-default;
        color: $palette-dark-emphasis;
        svg {
            path {
                /* clean-css ignore:start */
                d: path(
                    "M0,5.2h23.3v1.5H0V5.2z M19.4,0l5.3,5.5l-1.1,1l-5.3-5.4L19.4,0z M18,10.9l6-6.1l1,1L19,12L18,10.9z"
                );
                /* clean-css ignore:end */
            }
        }
    }

    &:focus {
        outline: $button-focus;
        text-decoration: underline;
    }

    &:active {
        background-color: darken($palette-link, 30);
        border-color: darken($palette-link, 30);
        color: $palette-light;
        text-decoration: none;
    }

    @include media(">=large") {
        padding: rem-calc(2 44 0 51);
    }
}

// Action
// -----------------------------------------------------------
%button-action {
    padding: 0;
    height: rem-calc(44);
    min-width: rem-calc(44);
    background-color: transparent;
    border: 0;
    color: $palette-link;
    text-transform: uppercase;

    .icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: rem-calc(48);
        min-width: rem-calc(48);
        background-color: $white;
        border: solid rem-calc(1) $palette-emphasis;
        border-radius: 9999rem;
        transition: background-color $global-transition;
    }

    &.with-text {
        &.button-left .icon-wrap {
            margin-right: rem-calc(10);
        }

        &.button-right .icon-wrap {
            margin-left: rem-calc(10);
        }
    }

    svg {
        height: rem-calc(12);
        width: rem-calc(25);
    }

    &:hover,
    &:focus {
        color: $white;
        .icon-wrap {
            background-color: $palette-dark;
            border-color: $white;
        }
    }

    &:active {
        color: darken($palette-link, 40);
        .icon-wrap {
            background-color: darken($palette-link, 40);
            border-color: darken($palette-link, 40);
        }
    }

    &:focus {
        .icon-wrap {
            outline: $button-focus;
        }
    }
}

// Styles
// -----------------------------------------------------------
%hollow {
    background-color: transparent;
    border-color: $palette-background-med;
    color: $palette-dark-emphasis;

    // .arrow-wrap {
    //     display: none;
    // }

    &:focus,
    &:hover,
    &:active {
        background-color: $palette-background-med;
        border-color: $palette-background-med;
        color: $palette-light-text;

        // .arrow-wrap {
        //     display: none;
        // }
    }

    &.inverted {
        // .arrow-wrap {
        //     display: none;
        // }

        &:focus,
        &:hover,
        &:active {
            .arrow-wrap {
                color: $palette-primary-lighter;
            }
        }
    }
}

%inverted {
    background-color: $palette-light;
    border-color: $palette-light;
    color: $palette-dark-text;

    &:hover,
    &:focus {
        background-color: $palette-background-med;
        border-color: $palette-background-med;
        color: $palette-light-text;
    }

    &.hollow {
        background-color: transparent;
        border-color: $white;
        color: $white;

        &:hover,
        &:focus {
            background-color: $white;
            color: $palette-dark-emphasis;
        }
    }
}

%text-link {
    justify-content: flex-start;
    position: relative;
    padding-left: 0;
    height: max-content;
    min-width: 0;
    width: max-content;
    background-color: transparent;
    border: 0;
    @include font-size($body-s);

    .arrow-wrap {
        color: $palette-light-text;
    }

    &:hover,
    &:focus {
        background-color: transparent;
        color: $palette-light-emphasis;
    }

    &:focus {
        outline: none;
    }

    &.inverted {
        color: $palette-dark-text;
        .arrow-wrap {
            color: $palette-dark-text;
        }
        &:hover,
        &:focus {
            color: $palette-dark-text;
            .arrow-wrap {
                color: $palette-emphasis;
            }
        }
    }
}

%button-tab {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: auto;
    min-width: 0;
    width: auto;
    background-color: transparent;
    border: 0;
    color: $palette-link;
    text-transform: uppercase;
    font-family: $typeface-global;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: rem-calc(-2);
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $palette-primary;
        opacity: 0;
        transition: opacity $global-transition;
    }

    &[aria-selected="true"],
    &:hover,
    &:focus {
        color: $palette-primary;
        &::after {
            opacity: 1;
        }
    }

    &[aria-selected="true"] {
        color: $palette-link;
        &::after {
            background-color: $palette-link;
        }
    }
}

// Application
// -----------------------------------------------------------

form {
    input[type="submit"],
    input[type="button"],
    input[type="reset"] {
        @extend %button;
        @extend %hollow;
        margin-top: rem-calc(24);
    }
}

.button {
    @extend %button;

    &.hollow {
        @extend %hollow;
    }
    &.inverted {
        @extend %inverted;
    }
    &.text-link {
        @extend %text-link;
    }
    &.button-tab {
        @extend %button-tab;
    }
    &.button-action {
        @extend %button-action;
    }
}

// Button Groups
.button-group {
    margin: $button-spacing 0 0;

    .button,
    .text-content {
        + .button,
        + .text-content {
            margin-top: $button-spacing/2;
        }
    }

    @include media(">small") {
        margin-left: -$button-spacing/2;

        .button,
        .text-content {
            &:first-child,
            + .button,
            + .text-content {
                margin-top: $button-spacing/2;
                margin-left: $button-spacing/2;
            }
        }
    }

    @include media(">=large") {
        margin-left: -$button-spacing;

        .button,
        .text-content {
            &:first-child,
            + .button,
            + .text-content {
                margin-top: $button-spacing;
                margin-left: $button-spacing;
            }
        }
    }

    // remove offset for centered blocks
    .is-centered & {
        margin-left: 0;
    }
}

.filter {
    .button,
    .text-content {
        margin: rem-calc(0);
        text-transform: uppercase;
    }
    .button {
        @extend %hollow;
        border-left-width: 0;
        &:first-child {
            border-left-width: rem-calc(1);
        }
    }
}

.tabs {
    .button,
    .text-content {
        margin: rem-calc(0 23 0 0);
        @include media(">=medium") {
            margin: rem-calc(0 46 0 0);
        }
    }
}

.button-group,
.filter,
.tabs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @include media(">small") {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        .is-centered & {
            justify-content: center;

            .button:first-child {
                margin-left: 0;
            }
        }
    }
}
