// ===========================================================
// Text Cards
// ===========================================================

.m-text-cards {
    .grid-row {
        @include grid-row;
        grid-row-gap: rem-calc(50);
    }

    &.dark {
        @extend .light-text;
    }

    .grid-col {
        .heading-eyebrow {
            color: $palette-light-emphasis;
        }
        h2 {
            margin-bottom: rem-calc(20);
        }
        .button-group {
            margin-top: rem-calc(18);
        }
    }

    @include media(">=medium") {
        .grid-row {
            @include grid-two-symmetric-cols;
            @include grid-columns;
            column-gap: 0;
        }

        .grid-col {
            padding: rem-calc(0 40);
            position: relative;

            &:not(:last-child) {
                &::before {
                    content: "";
                    background: $palette-background-light;
                    position: absolute;
                    width: rem-calc(2);
                    height: 100%;
                    right: 0;
                    top: 0;
                }
            }
        }

        &.two-columns {
            .header-row {
                @include grid-row();
                @include grid-single-col-wide();
                .header-cell {
                    grid-column: colcentered;
                }
            }

            .grid-row {
                row-gap: rem-calc(50);
            }
            .grid-col:nth-child(2n + 2) {
                &::before {
                    content: none;
                }
            }
        }

        &.three-columns {
            .grid-row {
                @include grid-three-symmetric-cols;
                @include grid-columns(3);
            }
        }
    }

    @include media(">=large") {
        .grid-row {
            @include grid-two-symmetric-cols-padded;
        }
        .grid-col {
            padding: rem-calc(0 50);

            h2 {
                margin-bottom: rem-calc(30);
            }

            .button-group {
                margin-top: rem-calc(28);
            }
        }

        &.two-columns {
            .grid-row {
                row-gap: rem-calc(70);
            }
        }
    }

    @include media(">=xlarge") {
        .grid-col {
            padding: rem-calc(0 72);
        }
    }

    @include media(">=#{$max-width+$page-padding-double}") {
        .container {
            max-width: none;
        }
    }

    @include media(">=xxlarge") {
        .container {
            max-width: rem-calc(1440);
            margin: 0 auto;
        }
    }
}
