// ===========================================================
// Aspect Ratio
// ===========================================================

@use "sass:math";

@mixin aspect-ratio-size($width, $height) {
  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: (math.div($height, $width)) * 100%;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  @include aspect-ratio-size($width, $height);
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
