// ===========================================================
// Text Styles
// ===========================================================

@mixin nav-style {
    @include font-size($body-m);
    font-weight: bold;
    text-transform: uppercase;
}

@mixin animated-underline {
    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: rem-calc(-4);
        left: 50%;
        height: rem-calc(1);
        width: 0;
        background-color: darken($palette-link, 15);
        transform: translateX(-50%);
        transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover,
    &:focus-visible {
        &::after {
            width: 100%;
        }
    }
}

@mixin animated-underline-reversed {
    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: rem-calc(-4);
        left: 50%;
        height: rem-calc(1);
        width: 100%;
        background-color: darken($palette-link, 15);
        transform: translateX(-50%);
        transition: width 300ms ease;
    }
    &:hover,
    &:focus {
        &::after {
            width: 0;
        }
    }
}

@mixin text-divider {
    position: relative;
    margin-left: rem-calc(18);
    &::before {
        content: " | ";
        color: $palette-primary-lighter-text;
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
    }
}

@mixin line-decoration {
    &::before {
        position: relative;
        content: "";
        display: block;
        width: rem-calc(72);
        height: rem-calc(2);
        margin-bottom: rem-calc(38);
        background: $palette-secondary;
    }
}
