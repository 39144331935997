// ===========================================================
// Pagination
// ===========================================================

.pagination-block {
    display: flex;
    justify-content: center;

    .load-more {
        margin-top: $module-space-small;
        @include media(">=medium") {
            margin-top: $module-space-medium;
        }
    }
}
