// ===========================================================
// Forms
// ===========================================================

.m-form {
    position: relative;

    @include media("<medium") {
        .col-text + .col-form {
            margin-top: rem-calc(34);
        }
    }

    &:nth-child(2) {
        padding-top: $module-space-small + 30px;
        @include media(">=medium") {
            padding-top: $module-space-medium + 50px;
        }
        @include media(">=large") {
            padding-top: $module-space + 70px;
        }
    }

    .container {
        @include grid-row;
        @include grid-columns;
        @include media(">=medium") {
            @include grid-two-asymmetric-cols;
        }
        @include media(">=large") {
            grid-template-columns:
                1fr [col1-start] repeat(3, 1fr)
                [col1-end] 1fr [col2-start] repeat(6, 1fr)
                [col2-end] 1fr;
        }
    }

    .col-text {
        text-align: center;

        a {
            position: relative;
            display: block;
            width: max-content;
            margin: rem-calc(2) auto 0;
            text-decoration: none;
            @include animated-underline;

            &::after {
                bottom: rem-calc(-1);
            }

            span {
                font-family: $typeface-global;
            }
            font-size: rem-calc(18);
            line-height: rem-calc(28);
        }

        @include media(">=medium") {
            text-align: left;

            a {
                margin: rem-calc(2 0 0);
            }
        }

        @include media(">=large") {
            a {
                font-size: rem-calc(20);
                line-height: rem-calc(32);
            }
        }
    }

    .col-form {
        text-align: center;

        @include media(">=medium") {
            text-align: left;
        }
    }

    // The big "0"
    .form-glyph {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 200%;
        min-width: rem-calc(410);
        opacity: 0.3;
        color: $palette-primary-lighter;
        transform: translate(-50%, -66%);
        z-index: -1;
        svg {
            height: 100%;
            width: 100%;
        }

        @include media(">=large") {
            height: 112%;
            width: 66%;
            max-width: rem-calc(800);
            transform: translate(-50%, -58%);
        }
    }
}

.m-form-two-cols.m-text-single {
    // .text-single-heading {
    // 	.h2 {
    // 		color: $palette-text;
    // 	}
    // }
    .text-single-container {
        @include media(">=large") {
            @include grid-single-col-wide;
        }
    }
    .text-single-content {
        max-width: rem-calc(746);
        margin: 0 auto;
    }
    form {
        max-width: rem-calc(800);
        margin: rem-calc(63) auto 0;
        padding: rem-calc(40 24 45);
        background: $white;
        border: rem-calc(1) solid $palette-primary-lighter;
        text-align: left;

        .h6 {
            margin-bottom: rem-calc(5);
        }
        p {
            font-size: rem-calc(14) !important;
        }

        @include media(">=medium") {
            padding: rem-calc(45 76 50);
        }

        @include media(">=large") {
            padding: rem-calc(64 128 80);
        }
    }
}
