// ===========================================================
// Image Carousel Module
// ===========================================================

.m-image-carousel {
  .carousel-wrapper {
    position: relative;
  }

  .carousel-text-container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 104px); // carousel height - button height underneath
  }

  .no-dots {
    .carousel-text-container {
      height: 100%;
    }
  }
}