.m-masonry-image-grid {
	.container {
		@include grid-row;
		@include grid-single-col;

		@include media(">=medium") {
			@include grid-single-col-full;
		}

		.image-wrapper {
			margin-bottom: 24px;
			height: fit-content;
			img {
				height: 100%;
				object-fit: cover;
			}
		}

		@include media("<medium") {
			.grid-col {
				display: flex;
				flex-direction: column;
			}
			img {
				max-height: rem-calc(400);
			}
		}

		@include media(">=medium") {
			.grid-col {
				display: grid;
				column-gap: 24px;
				grid-template-columns: repeat(6, 1fr);
				// grid-auto-rows: 2px;
				grid-auto-flow: row dense;
			}
			.image-wrapper {
				&:nth-child(9n + 1),
				&:nth-child(9n + 8) {
					@include aspect-ratio(738.66, 402);
					grid-column-end: span 4;
					grid-row-end: span 213;
				}

				&:nth-child(9n + 2) {
					@include aspect-ratio(357.33, 402);
					grid-column-end: span 2;
					grid-row-end: span 213;
				}

				&:nth-child(9n + 3),
				&:nth-child(9n + 4) {
					@include aspect-ratio(357.33, 390);
					grid-column-end: span 2;
					grid-row-end: span 207;
				}

				&:nth-child(9n + 5),
				&:nth-child(9n + 9) {
					@include aspect-ratio(357.33, 256);
					grid-column-end: span 2;
					grid-row-end: span 140;
				}

				&:nth-child(9n + 6),
				&:nth-child(9n + 7) {
					@include aspect-ratio(166.66, 256);
					grid-row-end: span 140;
				}

				&:last-child:nth-child(5) {
					@include aspect-ratio(357.33, 390);
				}
			}
		}
	}
}
