// Video
.video-slide {
    .js-pause-btn {
        display: none;
        position: absolute;
        right: rem-calc(20);
        bottom: rem-calc(20);
        width: rem-calc(60);
        height: rem-calc(60);
        padding: rem-calc(0);
        background-color: transparent;
        border: rem-calc(1) solid rgba($white, 0.85);
        border-radius: 50%;
        z-index: 5;
        transition: background-color $global-transition;

        svg {
            width: rem-calc(20);
            height: rem-calc(20);
            padding-top: rem-calc(5);
            fill: rgba($white, 0.85);
            transition: fill $global-transition;
        }

        &:hover,
        &:focus {
            background-color: rgba($white, 0.85);
            svg {
                fill: $palette-secondary;
            }
        }

        svg.svg-icons--play-arrow-dims {
            margin-left: rem-calc(4.5);
        }

        @include media(">=medium") {
            display: inline;
        }
    }
}
