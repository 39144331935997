// ===========================================================
// Text Single
// ===========================================================

.m-text-single {
    .text-single-container {
        @include grid-row;
        @include grid-single-col;

        .text-single-heading {
            text-align: center;
            margin-bottom: rem-calc(28);

            // h2 {
            //     color: $palette-dark-emphasis;
            // }

            .heading-eyebrow:not(.compact),
            .subtitle:not(.feat-heading) {
                @include font-size($label-xs);
            }
        }

        .text-single-buttons {
            margin-top: rem-calc(32);
            justify-content: center;
        }

        p + ul {
            margin-top: rem-calc(30);
        }

        @include media(">=medium") {
            @include grid-single-col-wide;

            ul {
                margin: 0;
                padding: 0;
                column-count: 2;
                gap: rem-calc(32);
            }

            li {
                padding-left: rem-calc(43);
            }

            p + ul,
            p + ol {
                margin-top: rem-calc(47);
            }

            ul,
            ol {
                + p {
                    margin-top: rem-calc(47);
                }
            }

            p,
            ul,
            ol {
                + h2,
                + h3,
                + h4 {
                    margin-top: rem-calc(27);
                }
            }
        }

        @include media(">=large") {
            @include grid-single-col-medium;

            .text-single-heading {
                margin-bottom: rem-calc(42);

                .subtitle:not(.feat-heading) {
                    font-size: rem-calc(56);
                    line-height: rem-calc(64);
                }
            }

            .text-single-content {
                max-width: rem-calc(736);
                margin: 0 auto;
            }

            .text-single-buttons {
                margin-top: rem-calc(36);
            }
        }
    }

    &.line-draw {
        #line {
            position: relative;
            top: rem-calc(-15);
            stroke-width: 2px;
            stroke: $palette-primary;
            transition: all cubic-bezier(0.46, 0.03, 0.52, 0.96) 1s;
            opacity: 0;

            @include media(">=medium") {
                top: rem-calc(-65);
                margin-bottom: rem-calc(-35);
            }

            @include media(">=large") {
                top: rem-calc(-75);
                margin-bottom: rem-calc(-25);
            }
        }

        .illustration {
            @include media(">=large") {
                top: -100%;
            }
        }
    }

    &.is-centered {
        text-align: center;
    }

    &.is-full-width {
        @include media(">=medium") {
            &:last-child {
                p,
                ul,
                ol {
                    + h2,
                    + h3,
                    + h4 {
                        margin-top: rem-calc(35);
                    }
                }

                h2,
                h3 {
                    + p,
                    + ul,
                    + ol {
                        margin-top: rem-calc(17);
                    }
                }

                .text-single-container {
                    p + ol,
                    p + ul {
                        margin-top: rem-calc(25);
                    }
                }
            }
        }
        &:not(.multi-column) {
            @include media(">=large") {
                @include grid-single-col-wide;
            }
        }
        .text-single-container {
            @include media(">=large") {
                @include grid-single-col-medium;
            }
        }
    }

    &.multi-column {
        @include media(">=medium") {
            .text-single-content {
                columns: 2;
                gap: 32px;
            }
        }
    }

    // &.amenities-listing {
    //     li {
    //         font-size: rem-calc(20);
    //         line-height: 1.5;
    //         position: relative;

    //         .list-content {
    //             p:not(.heading-eyebrow) {
    //                 font-size: rem-calc(16);
    //                 line-height: rem-calc(24);
    //             }
    //         }
    //     }

    //     &--large.has-large-paragraph {
    //         .text-single-content {
    //             > p {
    //                 margin: {
    //                     left: rem-calc(-5);
    //                     right: rem-calc(-5);
    //                 }
    //             }
    //             ul {
    //                 padding: 0;
    //                 text-align: left;

    //                 li {
    //                     padding-left: 0;
    //                     flex-direction: column;

    //                     .h7 {
    //                         color: $palette-text;
    //                     }

    //                     p:not(.heading-eyebrow) {
    //                         font-size: rem-calc(14);
    //                         line-height: rem-calc(24);
    //                     }

    //                     &::before {
    //                         content: none;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    &.has-line-decoration {
        .text-single-heading {
            @include line-decoration;
            &::before {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &.has-large-paragraph {
        .text-single-container {
            .text-single-content {
                p:not(.heading-eyebrow) {
                    @include font-size($body-xl);
                }
            }
        }
    }

    &.has-svg {
        .icon {
            color: $palette-primary;
            width: rem-calc(56);
            height: rem-calc(67);
            margin-bottom: rem-calc(20);

            @include media(">=large") {
                width: rem-calc(94);
                height: rem-calc(112);
                margin-bottom: rem-calc(33);
            }
        }

        &.has-background:not(:nth-child(2)) {
            margin-top: rem-calc(37);

            .icon {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -60%);
            }
        }

        .icon + .text-single-heading,
        .icon + .text-single-content {
            margin-top: rem-calc(20);

            @include media(">=large") {
                margin-top: rem-calc(33);
            }
        }
    }

    &.has-ft-paragraph {
        .text-single-container {
            .text-single-content {
                p:first-child {
                    @include font-size($lead-font-sizes);
                    margin-bottom: rem-calc(24);
                    color: $palette-dark-emphasis;
                    font-family: $typeface-header;

                    a {
                        color: inherit;
                    }
                }
                @include media(">=large") {
                    p:first-child {
                        margin-bottom: rem-calc(48);
                    }
                }
            }
        }
    }

    &.has-info-box {
        .text-single-content,
        .info-box {
            max-width: rem-calc(565);
            margin: 0 auto;
        }
        .info-box {
            padding: rem-calc(20 32 24);
            margin-top: rem-calc(28);
            background: $white;
            border: rem-calc(1) solid $palette-primary;

            .info-box-heading {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                width: rem-calc(14);
                height: rem-calc(20);
                margin-right: rem-calc(16);
            }

            .heading-eyebrow {
                font-size: rem-calc(14);
                margin-bottom: 0;
            }

            p:not(.heading-eyebrow) {
                font-size: rem-calc(14);
                line-height: rem-calc(24);
                text-align: left;
                margin-top: rem-calc(10);
            }

            @include media(">=large") {
                padding: rem-calc(24 40 32);
                margin-top: rem-calc(40);
            }
        }
        @include media(">=large") {
            .h2 {
                margin: {
                    left: rem-calc(-10);
                    right: rem-calc(-10);
                }
            }
        }

        &.white {
            .info-box {
                background: $white;
            }
        }
    }

    .illustration {
        &.skew-left {
            img {
                transform: rotate(-20deg);
            }
        }
        &.skew-right {
            img {
                transform: rotate(20deg);
            }
        }
    }
}

.m-text-single:not(.has-background) + .m-text-single:not(.has-background) {
    margin-top: rem-calc(-20);
}
.section-container.has-background
    + .m-text-single.has-svg.has-background:not(:nth-child(2)) {
    margin-top: 0;

    @include media("<medium") {
        svg {
            position: relative;
            transform: none;
            left: initial;
        }
    }
}
