// ===========================================================
// Settings
// ===========================================================

@use "sass:math";

// Colors
// -----------------------------------------------------------

// Brand Colors
// $black: #000000;
// $white: #ffffff;
// $blue: #506a71;
// $dark-blue: #3d5157;
// $green: #657b6b;
// $light-green: #889d8e;
// $dark-gray: #3f3f42;
// $yellow: #b39c30;
// $aqua: #6ec6ac;
// $soft-aqua: #d9f0ea;
// $pale-aqua: #f2faf8;

$black: #000000;
$deep-black: #373731;
$black-blue: #414854;
$dark-blue: #6c7584;
$white: #ffffff;
$pale-beige: #faf9f5;
$soft-yellow: #f4f0cd;
$rust: #b77127;

$darkgreen: #003a2d;
$medgreen: #536e65;
$lightgreen: #d9eee3;

// Palette Colors
$palette-dark: $medgreen;
$palette-primary-lighter: $lightgreen;
$palette-primary: $white;
$palette-primary-darker: $darkgreen;
$palette-secondary: $medgreen;
$palette-primary-lighter-text: $white;
$palette-secondary-light-text: $white;
$palette-link: $darkgreen;
$palette-primary-lighter-border: $white;

$palette-light: $white;
$palette-medium: $medgreen;
$palette-dark: $darkgreen;
$palette-text: $darkgreen;
$palette-emphasis: $lightgreen;
$palette-border: $white;

// Success / Error
$palette-success: #2fa708;
$palette-error: #c92a07;
$palette-warning: #ffa500;

// Text Colors
$palette-text: $darkgreen;
$palette-light-text: $white;
$palette-dark-text: $darkgreen;
$palette-light-emphasis: $lightgreen;
$palette-dark-emphasis: $medgreen;

// Background Colors
$palette-background-default: $white;
$palette-background-light: $lightgreen;
$palette-background-med: $medgreen;
$palette-background-dark: $darkgreen;
$palette-background-overlay: rgba(0, 0, 0, 0.54);

// Form Colors
$palette-form-border: $palette-light;
$palette-form-disabled: rgba($palette-text, 0.25);
$palette-form-placeholder: rgba($palette-text, 0.5);
$palette-form-active: $palette-background-dark;

// Gradient
$palette-primary-gradient: linear-gradient(
    180deg,
    rgba(65, 72, 84, 0.78) 0%,
    rgba(65, 72, 84, 0.5) 100%
);
$palette-primary-shadow: rgba($black, 0.14) 0 rem-calc(3) rem-calc(10)
    rem-calc(2);

$palette-dark-border: $palette-primary;
$palette-primary-lighter-border: rgba($palette-border, 0.52);

// Globals
$global-background-color: $palette-background-default;
$global-border-color: $palette-border;
$global-brand-color: $palette-dark-emphasis;

// Layout
// -----------------------------------------------------------

// Grid
$max-width: 1120px;
$grid-gap: 32px;
$grid-gap-vertical: 2.85%;
$grid-gap-horizontal: 32px;

// Spacing
$module-space: 100px;
$module-space-medium: math.div($module-space, 1.5);
$module-space-small: math.div($module-space, 2);

// Base Properties
// -----------------------------------------------------------
$global-radius: 0;

// A11y
$a11y-outline: dashed 2px $palette-primary;
$a11y-outline-light: dashed 2px $white;

// Typography
// -----------------------------------------------------------

// Additional settings found in _typography.scss
$typeface-global: "TTNorms-Regular", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
$typeface-global-med: "TTNorms-Regular", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
$typeface-global-bold: "TTNooks-Bold", "Baskerville", "Baskerville Old Face",
    "Hoefler Text", Garamond, serif;
$typeface-global-italic: "TTNooksScript-Regular", "Baskerville",
    "Baskerville Old Face", "Hoefler Text", Garamond, serif;

$typeface-header: "TTNooks-Bold", "Baskerville", "Baskerville Old Face",
    "Hoefler Text", Garamond, serif;
$typeface-alt-header: "TTNooksScript-Regular", "Baskerville",
    "Baskerville Old Face", "Hoefler Text", Garamond, serif;
$typeface-secondary-header: "TTNooksScript-Regular", "Baskerville",
    "Baskerville Old Face", "Hoefler Text", Garamond, serif;

// Maps: (fontsize, lineheight, letterspacing)
// add pixels without units, eg: (16, 16, 1)
// $<el>-font-sizes: (
//   null  : (XX, XX, X.X),
//   small : (XX, XX, X.X),
//   medium: (XX, XX, X.X),
//   xlarge : (XX, XX, X.X),
// );

// $p-font-sizes: (
//     null: (
//         16,
//         18,
//         0,
//     ),
//     large: (
//         28,
//         42,
//         0,
//     ),
// );
$h1-font-sizes: (
    null: (
        40,
        50,
        0,
    ),
    large: (
        48,
        56,
        0,
    ),
);
$h2-font-sizes: (
    null: (
        40,
        50,
        0,
    ),
    large: (
        48,
        56,
        0,
    ),
);
$h3-font-sizes: (
    null: (
        35,
        40,
        0,
    ),
    large: (
        40,
        48,
        0,
    ),
);
$h4-font-sizes: (
    null: (
        36,
        42,
        0,
    ),
    large: (
        48,
        56,
        0,
    ),
);
$h5-font-sizes: (
    null: (
        28,
        36,
        0,
    ),
    large: (
        32,
        40,
        0,
    ),
);
$h6-font-sizes: (
    null: (
        24,
        28,
        0,
    ),
    large: (
        28,
        32,
        0,
    ),
);
$h7-font-sizes: (
    null: (
        20,
        24,
        0,
    ),
    large: (
        24,
        32,
        0,
    ),
);
$label-xxxl: (
    null: (
        24,
        28,
        0,
    ),
);
$label-xxl: (
    null: (
        24,
        28,
        0,
    ),
);
$label-xl: (
    null: (
        18,
        21,
        0,
    ),
);
$label-l: (
    null: (
        20,
        24,
        0,
    ),
);
$label-m: (
    null: (
        16,
        28,
        0,
    ),
);
$label-s: (
    null: (
        12,
        16,
        0,
    ),
);
$label-xs: (
    null: (
        10,
        14,
        0,
    ),
);
$label-xxs: (
    null: (
        10,
        12,
        0,
    ),
);
$body-xl: (
    null: (
        21,
        32,
        0,
    ),
    large: (
        21,
        32,
        0,
    ),
);
$body-l: (
    null: (
        18,
        28,
        0,
    ),
);
$body-m: (
    null: (
        16,
        28,
        0,
    ),
);
$body-s: (
    null: (
        14,
        24,
        0.1em,
    ),
    large: (
        16,
        28,
        0.1em,
    ),
);
$eyebrow-font-sizes: (
    null: (
        12,
        16,
        0.1em,
    ),
);
$l-eyebrow-font-size: (
    null: (
        18,
        24,
        0.1em,
    ),
);
$compact-eyebrow-font-sizes: (
    null: (
        16,
        28,
        0.1em,
    ),
);
$lead-font-sizes: (
    null: (
        40,
        48,
        0,
    ),
);
$blockquote-font-sizes: (
    null: (
        16,
        22,
        0,
    ),
    large: (
        24,
        40,
        0,
    ),
);
$footer-font-sizes: (
    null: (
        14,
        24,
        0,
    ),
);
$footer-nav-font-sizes: (
    null: (
        20,
        32,
        0,
    ),
);
$button-font: (
    null: (
        20,
        24,
        0.1em,
    ),
    large: (
        24,
        32,
        0.1em,
    ),
);

// Font Weight
// -----------------------------------------------------------

// Based on https://www.w3.org/TR/css-fonts-3/#font-weight-numeric-values
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-book: 350;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Transitions
// -----------------------------------------------------------
$global-transition-duration: 300ms;
$global-transition-timing-function: ease;
$global-transition: $global-transition-duration
    $global-transition-timing-function;

// Breakpoints
// -----------------------------------------------------------
// 	https://github.com/eduardoboucas/include-media
$breakpoints: (
    xsmall: 360px,
    small: 640px,
    medium: 768px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
    vertical: 768px,
    custom: 1330px,
    customline: 1280px,
    // custom = headers with 2 ctas
);
