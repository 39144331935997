.m-testimonials {
	text-align: center;

	.text-header {
		margin-bottom: rem-calc(25);
	}

	.testimonials-wrapper {
		max-width: rem-calc(736);
		margin: 0 auto;
		margin-bottom: rem-calc(32);
	}

	.svg-container {
		display: block;
		position: relative;
		margin: 0 auto;
		width: rem-calc(100);
		height: rem-calc(100);
		margin-bottom: rem-calc(30);

		svg.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
		}

		// svg.text {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	animation: 15s rotate infinite normal linear;
		// }
	}

	blockquote {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		p.quote {
			width: 100%;
			font: {
				family: $typeface-global;
				size: rem-calc(16);
			}
			line-height: rem-calc(28);
		}
		p.author {
			margin-top: rem-calc(15);
			font-family: $typeface-global;
			text-transform: uppercase;
			letter-spacing: 0.155em;
			color: $palette-primary-lighter;
		}
	}

	.custom-page-dots {
		margin-top: 0;
		justify-content: center;
		.number {
			color: $palette-primary;
			&::after {
				background-color: $palette-primary;
			}
		}
	}

	@include media(">=large") {
		.testimonials-wrapper {
			margin-bottom: rem-calc(42);
		}
		.svg-container {
			width: rem-calc(142);
			height: rem-calc(142);
			margin-bottom: rem-calc(40);

			// svg.icon {
			// 	top: calc(50% - 6px);
			// 	width: rem-calc(42);
			// 	height: rem-calc(66);
			// }
		}
		.text-header {
			margin-bottom: rem-calc(34);
		}
		blockquote {
			p.quote {
				font-size: rem-calc(28);
				line-height: rem-calc(42);
			}
			p.author {
				margin-top: rem-calc(32);
			}
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-360deg);
		}
	}
}
