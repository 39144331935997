// ===========================================================
// Resets
// ===========================================================

// Remove List Styles
.list-style-none {
    margin: 0;
    padding: 0;
    li {
        display: list-item;
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;

        &::before {
            content: none;
        }
    }
}

// Remove all button Styles
.button-style-none {
    margin: 0;
    padding: 0;
    border: 0;
    display: inline-block;
    background-color: transparent;
    border-radius: 0;
}

// Remove Box Shadow
%no-box-shadow {
    box-shadow: inset 0 0 0 0 transparent !important;
}
