// ===========================================================
// Lightbox
// ===========================================================

.goverlay,
.glightbox-mobile .goverlay {
    background: rgba($white, 0.95);
}

.glightbox-container {
    .gslide,
    .gslide.current {
        height: 100%;
    }

    .ginner-container {
        max-width: rem-calc(1000) !important;
        height: 100%;
        max-height: 80vh;
    }
}

.glightbox-clean {
    .ginner-container {
        flex-direction: column;
        align-items: center;

        .gslide-media {
            box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.45);
            position: absolute;

            &.gslide-image {
                img {
                    max-height: 80vh;
                    margin: 0;
                }
            }
        }
    }

    .gclose {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $palette-primary;
        transition: background-color $global-transition;
    }

    .gnext,
    .gprev {
        @extend .button;
    }
    .gnext,
    .gprev,
    .gclose {
        background-image: none;
        background-color: $palette-primary;
        width: rem-calc(60);
        height: rem-calc(60);
        min-width: rem-calc(60);
        padding: rem-calc(0 10);

        svg {
            width: rem-calc(22);
            height: rem-calc(22);
            fill: $white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            transition: transform $global-transition;
        }

        &:hover,
        &:focus {
            background-color: darken($palette-primary, 20);
            svg {
                transform: translate3d(-50%, -50%, 0) scale(1.1);
            }
        }
    }

    .gnext,
    .gprev {
        top: 50%;
        transform: translate(0, -50%);
    }

    .gnext {
        @include media("<small") {
            right: 0;
        }
    }

    .gprev {
        @include media("<small") {
            left: 0;
        }
    }

    .gprev {
        svg {
            transform: rotate(180deg) translate3d(50%, 50%, 0);
        }

        &:hover,
        &:focus {
            svg {
                transform: rotate(180deg) translate3d(50%, 50%, 0) scale(1.1);
            }
        }
    }

    .gclose {
        top: 0;
        @include media(">=medium") {
            right: rem-calc(30);
        }
    }
}
