// ===========================================================
// Flyout Toggle
// ===========================================================

.flyout-toggle {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border: none;
	color: $palette-primary;
	svg {
		height: rem-calc(10);
		width: rem-calc(15);
	}

	&:focus,
	&:hover {
		color: $palette-dark;
	}

	&.close-flyout-panel {
		justify-content: flex-start;
		position: relative;
		padding: rem-calc(15 0) !important;
		width: 100% !important;
		font: {
			family: $typeface-global;
			size: rem-calc(12);
			weight: $font-weight-bold;
		}
		line-height: rem-calc(16);
		letter-spacing: 0.155em;
		text-transform: uppercase;

		span {
			position: relative;
			color: $palette-text;
			@include animated-underline;
		}

		&:hover,
		&:focus-visible {
			span::after {
				width: 100%;
			}
		}

		svg {
			margin-bottom: rem-calc(2px);
			transform: rotate(180deg);
			margin-right: rem-calc(10);
		}

		@include media("<=large") {
			margin-bottom: rem-calc(31);
			border-bottom: 1px solid $palette-primary;
		}
	}
}
