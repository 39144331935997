.m-carousel {
    // Regular Image + Text styles
    &.text-carousel {
        &.section-container {
            padding: rem-calc(55) 0;
            @include media(">=medium") {
                padding-top: rem-calc(100);
            }
            &.experience-card {
                padding-bottom: rem-calc(40);
            }
        }

        .grid-row {
            @include grid-row;
            align-items: center;
            grid-row-gap: 0;
            @include media("<medium") {
                .grid-col {
                    &.carousel-text-container {
                        order: 2;
                    }
                    &.carousel-container {
                        order: 1;
                    }
                }
            }
        }

        .carousel-buttons:not(.button-group) {
            left: 50%;
            width: calc(65vw + 48px);
            padding: 0;
            transform: translate(-50%, -50%);
        }

        .carousel {
            width: 100%;
            height: auto;
            opacity: 0;
            transition: opacity 0.4s;

            .flickity-viewport {
                position: relative;
                width: 100%;
                transition: height 0.2s;
            }

            &.flickity-enabled {
                opacity: 1;
            }

            .image-slide {
                height: 100%;
            }

            @include media("<medium") {
                .carousel-image {
                    min-height: rem-calc(324);
                    width: 65vw;
                    margin-right: rem-calc(16);
                    padding: 0;
                    transition: transform 400ms ease 200ms;

                    &:not(.is-selected) {
                        transform: scaleY(0.926);
                        transition: transform 200ms ease;
                    }
                }
            }
        }

        .carousel-single {
            @include aspect-ratio(375, 293);
            overflow: hidden;
            // box-shadow: -3px -3px 0.7em rgb(31, 31, 31), -32px -32px $dark-blue;

            .image-slide {
                position: absolute;
                top: 0;
                height: 100%;
            }
        }

        .carousel-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            // color: $palette-text;
            position: relative;
            padding: 0;

            .count {
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem-calc(38);
                height: rem-calc(38);
                margin-top: rem-calc(-6);
                margin-bottom: rem-calc(17);
                border-radius: 50%;
                font: {
                    family: $typeface-global;
                    size: rem-calc(12);
                }
                text-align: center;
                letter-spacing: 0.155em;
                background: $pale-beige;
            }

            .venue-info {
                display: flex;
                flex-flow: column;
                row-gap: rem-calc(3);
                margin-top: rem-calc(22);
                font-size: rem-calc(12);
                line-height: rem-calc(14);
                letter-spacing: 0.05em;

                .venue-info-item {
                    display: inline-flex;
                    margin-bottom: 0.5rem;
                }

                .break {
                    flex-basis: 100%;
                    height: 0;
                }

                .value {
                    font-family: $typeface-global;
                    margin-left: rem-calc(5);
                    order: 2;
                    text-transform: uppercase;
                    color: $palette-text;
                }

                .label {
                    order: 1;
                    font-size: rem-calc(20);
                    color: $palette-primary;
                    font-family: $typeface-alt-header;
                }
            }

            .venue-info + .carousel-content {
                margin-top: rem-calc(17);
            }

            ul {
                margin-left: rem-calc(18);
                padding-left: 0;
            }

            .subtitle {
                font: {
                    family: $typeface-header;
                    size: rem-calc(20);
                }
                color: $palette-primary;
            }

            .heading-eyebrow {
                margin-bottom: rem-calc(18);
                @include font-size($l-eyebrow-font-size);
            }

            .carousel-buttons {
                justify-content: flex-start;
                padding: 0;
            }

            .button-group:not(.custom-page-dots) {
                @include media(">=medium") {
                    width: min-content;
                    margin-top: rem-calc(22);
                    row-gap: rem-calc(20);

                    .button {
                        min-width: max-content;
                        width: 100%;
                    }
                }
            }
        }

        .carousel-content {
            margin-top: rem-calc(27);
        }

        .carousel-wrapper {
            position: relative;
            @include media("<medium") {
                margin-bottom: rem-calc(30);
            }

            .custom-page-dots {
                display: none;
                position: absolute;
                bottom: rem-calc(-45);
                width: 100%;
                justify-content: center;
                margin-top: rem-calc(35);

                .number::after {
                    background-color: $palette-primary;
                }
            }
        }

        @include media("<medium") {
            .carousel-text-container {
                padding-top: rem-calc(24);
            }
            .button-group {
                .button {
                    height: rem-calc(72);
                }
            }
        }

        @include media(">=medium") {
            &.section-container {
                padding-bottom: $module-space-medium + 40px;

                &.experience-card {
                    padding-bottom: rem-calc(60);
                }
            }

            .grid-row {
                @include grid-two-symmetric-cols;
                @include grid-columns;
            }

            .carousel-wrapper {
                z-index: -1;

                .custom-page-dots {
                    display: flex;
                }
            }

            .carousel-buttons:not(.button-group) {
                display: none;
            }

            .carousel,
            .carousel-single {
                @include aspect-ratio(640, 698);

                .flickity-viewport {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 100% !important; // helps fill full height
                    width: 100%;
                }
            }

            .carousel-text-container {
                .count {
                    margin-top: 0;
                    margin-bottom: rem-calc(20);
                }
            }

            &.is-reversed {
                .grid-row {
                    @include grid-two-symmetric-cols;
                    @include grid-columns-reversed;
                }
                .grid-col.carousel-text-container {
                    margin-left: $grid-gap-horizontal;
                }

                .carousel-single {
                    margin-right: 0;
                    margin-left: calc((100vw - 1120px) / -2);

                    @media (max-width: 1600px) {
                        margin-left: -240px;
                    }
                }
            }

            &.has-illustration {
                .grid-col {
                    &:nth-of-type(2) {
                        grid-column: col1 !important;
                    }

                    &:last-child {
                        grid-column: col2;
                    }
                }
            }
        }

        @include media(">=large") {
            .grid-row {
                grid-template-columns:
                    0fr [col1-start] repeat(5, 1fr)
                    [col1-end] 1fr [col2-start] repeat(5, 1fr)
                    [col2-end];
                .grid-col {
                    &.carousel-text-container {
                        margin-left: -$grid-gap-horizontal;
                    }
                }
            }

            &.is-reversed {
                .grid-row {
                    grid-template-columns:
                        [col1-start] repeat(5, 1fr)
                        [col1-end] 1fr [col2-start] repeat(5, 1fr)
                        [col2-end] 1fr;
                }
                .grid-col {
                    &.carousel-text-container {
                        margin-left: auto;
                    }
                }
            }

            .carousel-text-container {
                .heading-eyebrow {
                    margin-bottom: rem-calc(24);
                }
                .venue-info {
                    padding: rem-calc(6);
                    margin-left: rem-calc(-12);
                    margin-right: rem-calc(-12);
                    border: {
                        top: rem-calc(1) solid $palette-secondary;
                        bottom: rem-calc(1) solid $palette-secondary;
                    }
                    width: calc(100% + 24px);
                    flex-flow: row;

                    .venue-info-item {
                        flex-wrap: wrap;
                        flex-basis: 33.333%;
                        align-content: flex-start;
                        row-gap: rem-calc(8);
                        padding: rem-calc(10 14);
                        border-left: rem-calc(1) solid $palette-secondary;
                        margin-bottom: 0;

                        &:first-of-type {
                            border-left: none;
                            padding-left: 0;
                        }

                        &:last-of-type {
                            padding-right: 0;
                            margin-bottom: 0;
                        }
                    }
                    .break {
                        display: none;
                    }
                    .value {
                        flex: 1 0 100%;
                        order: 1;
                        height: fit-content;
                        font: {
                            family: $typeface-header;
                            size: rem-calc(18);
                        }
                        line-height: rem-calc(21);
                        text-transform: none;
                        margin: 0;
                    }
                    .label {
                        order: 2;
                    }
                }
            }

            .carousel-content {
                margin-top: rem-calc(32);
                // max-width: rem-calc(384);

                // p {
                //     font-size: rem-calc(18);
                //     line-height: 1.333;
                // }
            }

            .carousel,
            .carousel-single {
                max-height: rem-calc(698);
            }

            .carousel-single {
                margin-right: calc((100vw - 1120px) / -2);

                @media (max-width: 1600px) {
                    margin-right: -240px;
                }
            }
        }

        @include media(">=xlarge") {
            &.section-container {
                padding-bottom: $module-space + 20px;

                &.experience-card {
                    padding-bottom: rem-calc(80);
                }
            }
        }
    }

    // Extra Wide Images & Text w box container
    &.has-text-box.text-carousel {
        .container {
            display: flex;
            flex-direction: column;
        }
        .carousel-text-container {
            height: auto;
            padding: rem-calc(40 24 32);
            max-width: rem-calc(512);
            background-color: $white;

            .heading-eyebrow {
                color: $palette-primary;
                margin-bottom: rem-calc(11);
            }
        }

        &.stacked {
            .carousel-container.grid-col {
                grid-column: 1 / span 12 !important;
            }
            .carousel-text-container {
                position: relative;
                margin-top: -145px;
                width: 80%;
                grid-column: 1 / span 12 !important;
                grid-row: 2 !important;
                align-items: center;
                text-align: center;
                z-index: 1 !important;

                @include media("<small") {
                    width: 100%;
                }

                .carousel-buttons {
                    justify-content: center;
                    align-content: center;

                    .text-link {
                        padding-right: 0;
                    }
                }

                .carousel-heading {
                    h2 {
                        @include font-size($h5-font-sizes);
                    }
                }
            }
        }
        &.white {
            .carousel-text-container {
                background-color: $white;
            }
        }
        @include media("<medium") {
            .container {
                .grid-col {
                    &.carousel-text-container {
                        order: 2;
                        z-index: 0;
                    }
                    &.carousel-container {
                        order: 1;
                        width: 100vw;
                        margin: rem-calc(0 -24);
                    }
                }

                .carousel-text-container {
                    &.carousel-sibling {
                        margin-top: rem-calc(-50);
                        padding-top: rem-calc(96);
                    }
                }
            }
        }
        @include media(">=medium") {
            .grid-row {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                align-items: flex-start;

                .grid-col {
                    &.carousel-text-container {
                        grid-area: 1 / 1 / 2 / 7;
                        margin-left: 0;
                    }
                    &.carousel-container {
                        grid-area: 1 / 6 / 2 / 13;
                    }
                }
            }

            &.is-reversed {
                .grid-col {
                    &.carousel-text-container {
                        grid-area: 1 / 7 / 2 / 13;
                    }
                    &.carousel-container {
                        grid-area: 1 / 1 / 2 / 8;
                        margin-right: 0;
                    }
                }
            }

            .carousel,
            .carousel-single {
                @include aspect-ratio(561, 572);
                max-height: rem-calc(600);
            }

            .carousel-text-container {
                margin-top: rem-calc(42);
            }

            &.stacked {
                .carousel,
                .carousel-single {
                    @include aspect-ratio(1120, 452);
                }
                .carousel-text-container {
                    left: 50%;
                    transform: translateX(-50%);
                    max-width: 600px;
                    width: 54%;
                }
            }
        }
        @include media(">=large") {
            .carousel-text-container {
                padding: rem-calc(72 80 56);
                margin-top: rem-calc(80);
            }

            .carousel-content {
                margin-top: rem-calc(17);
                max-width: 100%;
                p {
                    @include font-size($body-m);
                    //    line-height: rem-calc(28);
                }
            }

            .carousel,
            .carousel-single {
                @include aspect-ratio(638, 600);
            }

            &.stacked {
                .carousel-text-container {
                    padding: rem-calc(47 92 58);

                    p {
                        @include font-size($body-m);
                        //   line-height: rem-calc(24);
                    }
                }
            }
        }

        + .has-text-box.text-carousel {
            padding-top: rem-calc(10);
        }
    }

    &.has-text-box.text-carousel.experience-card {
        .carousel-heading {
            .h4 {
                margin-bottom: 0;
            }
        }

        .count {
            background-color: $palette-secondary;
            font-weight: $font-weight-medium;
        }

        @include media("<medium") {
            .carousel-container {
                width: 100vw;
                margin: rem-calc(0 -24);
            }
        }

        @include media(">=medium") {
            .carousel-text-container {
                padding: rem-calc(36 40 42);
                width: 102%;
                margin-top: rem-calc(126);
            }
        }

        @include media(">=large") {
            .carousel-text-container {
                padding: rem-calc(48 60 56);
                margin-top: rem-calc(168);
            }
        }
    }

    &.stacked {
        .illustration {
            @include media(">=large") {
                top: -50%;

                img {
                    width: rem-calc(700);
                }
            }
        }
    }
}

.top-illustration {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60px;
}

.m-venues-listing {
    padding-top: 100px;
    background: $black;
}

.top-fork {
    position: relative;
    top: rem-calc(-84.32);
    display: flex;
    align-items: center;
    justify-content: center;

    .xfork {
        height: 68px;
        width: 64px;
    }

    @include media(">medium") {
        top: rem-calc(-136);
    }
}
