// Image
.m-image.is-full-width {
	.image-slide {
		width: 100%;
	}

	.custom-page-dots {
		justify-content: center;
		margin-top: rem-calc(16);
		z-index: 10;

		.number::after {
			background: $palette-primary;
		}
	}

	.image-slide {
		padding: 0;
		min-height: rem-calc(324);
		max-width: calc(100vw - 51px);
		margin-right: rem-calc(16);
	}

	.carousel-single {
		.image-slide {
			margin-right: 0;
		}
	}

	@include media(">=medium") {
		.image-slide {
			max-width: none;
			margin-right: 0;
		}
	}

	@include media(">=large") {
		.custom-page-dots {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			padding: rem-calc(24 32);
			background: $white;
		}
	}

	@include media(">=xxlarge") {
		.container {
			max-width: rem-calc(1440);
			margin: 0 auto;
		}
	}
}
