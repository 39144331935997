body .buorg {
  color: $white;
  background-color: $palette-emphasis;
  font-family: $typeface-global;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  border: none;
}

body div.buorg-pad {
  padding: 20px;
  line-height: 1.57143;
}
body .buorg-buttons {
  padding-top: 20px;
}

body #buorgig,
body #buorgul,
body #buorgpermanent {
  @extend .button;
  box-shadow: none;
  border-radius: 0;

  @include media(">=medium") {
    margin: 0 10px;
  }
}

body #buorgig {
  @extend .inverted;
}

body .buorg-test {
  display: none !important;
}

body .buorg-fadeout {
  transition: visibility 0s 5.5s, opacity 3s ease-out 2.5s;
}