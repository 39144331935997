.m-carousel.content-carousel.text-carousel.has-text-box {
	.js-content-text {
		width: 100%;

		.text-slide {
			max-width: 100%;
		}
	}
	.carousel-text-container {
		position: relative;
		width: 100%;
	}

	.custom-page-dots {
		position: absolute;
		top: rem-calc(208);
		left: 50%;
		transform: translateX(-50%);
		margin: 0;

		.number::after {
			background-color: $palette-primary;
		}

		@include media(">=medium") {
			transform: none;
			left: rem-calc(24);
			top: rem-calc(35);
		}

		@include media(">=large") {
			left: rem-calc(59);
			top: rem-calc(56);
		}
	}

	.heading-circle {
		text-align: center;
		.heading-eyebrow {
			margin-bottom: rem-calc(7);
		}
	}

	.illustration {
		position: absolute;
		bottom: rem-calc(-75);
		left: 70%;
		height: rem-calc(214);
		width: rem-calc(350);
		display: none;

		img {
			height: 100%;
			object: {
				fit: contain;
				position: bottom right;
			}
		}
	}

	@include media("<medium") {
		.carousel {
			.carousel-image {
				width: 70vw;
				min-height: rem-calc(160);

				&:not(.is-selected) {
					transform: none;
				}
			}
		}
		.container {
			.carousel-text-container.grid-col {
				margin-top: rem-calc(-184);
				padding-top: rem-calc(252);
				z-index: -1;
			}
			.heading-circle {
				padding-bottom: rem-calc(55);
				.line-1 {
					display: block;
					font-size: rem-calc(32);
					line-height: rem-calc(38);
					margin-bottom: rem-calc(-7);
				}
				.line-2,
				.line-3 {
					font-size: rem-calc(42);
					line-height: rem-calc(42);
				}
			}
		}
	}

	@include media(">=medium") {
		.grid-row {
			align-items: flex-start;
		}
		.carousel-text-container {
			padding-top: rem-calc(75);
			margin-top: rem-calc(140);

			.carousel-content {
				max-width: rem-calc(432);
			}
		}
		.heading-circle {
			position: absolute;
			left: calc(100% / 4);
			top: rem-calc(-27);
			width: rem-calc(230);
			height: rem-calc(230);
			padding: rem-calc(53 35 49);
			border-radius: 50%;
			background: $palette-primary;
			z-index: 2;
			span {
				color: $white;
			}
			h2 {
				display: flex;
				flex-direction: column;
				max-height: 100%;
				max-width: 100%;
				font-size: rem-calc(43);
				line-height: 0;
			}
			.line-1,
			.line-2,
			.line-3 {
				line-height: 1;
				white-space: nowrap;
			}
			.line-1,
			.line-3 {
				font-size: rem-calc(33);
			}
			.line-2 {
				font-size: rem-calc(43);
			}
		}
		.illustration {
			display: block;
			transform: none;
		}
	}

	@include media(">=large") {
		.button-group:not(.custom-page-dots) {
			margin-left: rem-calc(-11);
		}
		.carousel-text-container {
			padding-top: rem-calc(120);
			margin-top: rem-calc(190);
		}
		.heading-circle {
			width: rem-calc(305);
			height: rem-calc(305);
			padding: rem-calc(71 47 65);

			.line-1,
			.line-3 {
				font-size: rem-calc(44);
			}
			.line-2 {
				font-size: rem-calc(58);
			}
		}
		.illustration {
			bottom: rem-calc(-95);
		}
	}
}

.home {
	.m-carousel.content-carousel.text-carousel {
		@include media(">=medium") {
			.heading-circle {
				background: $white;
				.heading-eyebrow {
					color: $palette-text;
				}
				.line-1 {
					color: #7e8995;
				}
				.line-2,
				.line-3 {
					color: $palette-primary;
				}
			}
		}
	}
}
