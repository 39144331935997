// ===========================================================
// Instagram Feed
// ===========================================================

.m-instagram-feed {
    text-align: center;

    &:last-child {
        padding-bottom: 0;
    }

    .container {
        margin: {
            right: 0;
            left: 0;
        }
        max-width: none;
    }
    .module-header {
        display: flex;
        flex-flow: row wrap;
        column-gap: rem-calc(6);
        justify-content: center;
        margin-bottom: rem-calc(26);
        padding-top: rem-calc(80);

        h2 {
            flex: 100%;
        }

        .heading-eyebrow {
            margin-bottom: rem-calc(9);
            color: $palette-dark-text;
            @include font-size($l-eyebrow-font-size);
        }

        @include media(">=medium") {
            margin-bottom: rem-calc(42);
        }
        @include media("<medium") {
            .h3 {
                font-size: rem-calc(48);
            }
        }
    }

    .instagram-feed-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .col-image {
            position: relative;
            padding-top: 50%;
            width: 50%;

            &:nth-child(n + 5),
            &::before {
                display: none; /* Default override - Allow for link to be clickable */
            }

            .instagram-feed-image,
            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            img {
                object-fit: cover;
                opacity: 1;
                transition: opacity $global-transition;
            }
            .instagram-feed-image {
                background: $black;
                &:hover,
                &:focus {
                    img {
                        opacity: 0.83;
                    }
                }
            }
        }

        @include media(">=medium") {
            flex-wrap: nowrap;
            gap: rem-calc(8);

            .col-image {
                width: 25%;
                padding-top: 25%;
            }
        }

        @include media(">=large") {
            overflow: hidden;

            .col-image {
                padding-top: 0;
                height: rem-calc(256);
                width: rem-calc(256);
                flex-shrink: 0;

                &:nth-child(n + 5) {
                    display: block;
                }
            }
        }
    }
}

.home .m-instagram-feed {
    padding-top: 0 !important;

    &:nth-last-child(3) {
        padding-bottom: 0;
    }
}

.instagram-heading {
    color: $palette-dark-emphasis;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 24px;
    text-transform: none;

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: $palette-dark-emphasis;
        flex: 1;
        margin-top: 10px;
        max-width: 418px;
    }

    &:before {
        margin-right: 56px;

        @media (max-width: 800px) {
            margin-right: 28px;
        }
    }

    &:after {
        margin-left: 56px;

        @media (max-width: 800px) {
            margin-left: 28px;
        }
    }
}
