.m-image-plain-text {
	.container {
		@include grid-row;
		@include grid-single-col;
		row-gap: rem-calc(40);

		@include media(">=medium") {
			@include grid-single-col-full;
			row-gap: rem-calc(80);
		}
	}
	.grid-col {
		display: flex;
		flex-flow: row wrap;
		row-gap: rem-calc(16);

		.image-col,
		.text-col {
			flex-basis: 100%;
		}

		h2 + p,
		h3 + p {
			margin-top: rem-calc(23);
		}

		@include media(">=medium") {
			column-gap: rem-calc(32);

			.image-col {
				flex-basis: calc(40% - 16px);

				img {
					height: 100%;
					object-fit: cover;
				}
			}
			.text-col {
				flex-basis: calc(60% - 16px);
			}
		}

		@include media(">=large") {
			column-gap: rem-calc(64);

			.image-col {
				flex-basis: 34.28%;
			}
			.text-col {
				flex-basis: 51.4%;
			}
		}
	}
}
