// ===========================================================
// Light Text
// ===========================================================

.light-text {
    color: $white;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    cite,
    blockquote,
    p,
    ul,
    ol,
    dl,
    a {
        color: $white;
    }

    // Focus States
    *:focus {
        outline: $a11y-outline-light;
    }
}
