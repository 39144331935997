// ===========================================================
// Post Slider
// ===========================================================

.m-post-slider {
    &.section-container {
        padding-bottom: $module-space;
    }

    .container {
        @include grid-row;
        @include grid-single-col;

        @include media(">=medium") {
            @include grid-single-col-full;
        }

        &.extra-wide {
            max-width: none;
            @include media("<xxlarge") {
                .cards-container {
                    margin-left: rem-calc(-24);
                    margin-right: rem-calc(-24);
                }
            }
            @include media(">=xxlarge") {
                max-width: rem-calc(1440);
                margin: 0 auto;

                .cards-container {
                    margin: 0 auto;
                }
            }
        }
    }

    .desktop-prev-click-area,
    .desktop-next-click-area {
        height: rem-calc(214);
    }

    &.large-cards {
        .desktop-prev-click-area,
        .desktop-next-click-area {
            height: rem-calc(348);
        }
    }

    .post-slider-row {
        position: relative;
    }

    .post-slider-item {
        display: flex;
        flex-direction: column;

        .card-content {
            flex: 1;
        }
    }

    .post-slider-heading {
        margin-bottom: rem-calc(40);
        // @include line-decoration;

        .h2 {
            color: $palette-text;
        }

        // &::before {
        //     left: 50%;
        //     transform: translateX(-50%);
        // }

        + .cards-container {
            padding-top: rem-calc(16);
        }
    }

    .post-slider-content {
        max-width: rem-calc(746);
        margin: 0 auto rem-calc(47);
    }

    .cards-container {
        @include media("<large") {
            margin-right: rem-calc(-24);
        }
    }

    .carousel {
        width: 100%;
        height: auto;
        opacity: 0;
        transition: opacity 0.4s;

        &.flickity-enabled {
            opacity: 1;
        }
    }

    .carousel.js-card-mobile {
        &:after {
            content: "flickity";
            display: none;
        }
    }

    .flickity-viewport {
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .special-heading {
        span,
        label {
            background-color: $palette-primary-lighter;
        }
    }

    .flickity-slider {
        display: flex;
        position: static;
    }

    @include media(">=large") {
        .carousel.js-card-mobile {
            display: flex;
            &:after {
                //remove carousel on desktop
                content: "";
            }
        }

        &.mobile-carousel {
            // carousel turned off, now regular cards
            .post-slider-item {
                &:last-child {
                    margin-right: 0;
                }
                .card-content {
                    height: 100%;
                }
            }
        }

        .carousel {
            opacity: 1;
            margin: 0;
        }
    }

    &.white {
        .post-slider-item .card-content {
            background-color: $white;
        }
    }
}

.special-heading {
    position: relative;
    margin-bottom: rem-calc(47);
    font: {
        family: $typeface-global;
        size: rem-calc(12);
    }
    span,
    label {
        padding: rem-calc(0 32);
    }
    &::before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $palette-secondary;
        z-index: -1;
    }
}
