// ===========================================================
// Cookie disclosure
// ===========================================================

#cookie-disclosure {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: rem-calc(28);
    text-align: center;
    background-color: $palette-background-light;
    color: $palette-dark-text;
    transition: transform $global-transition;
    z-index: 300;
    a {
        color: $palette-dark-emphasis;
    }
    .close-cookie {
        margin-top: rem-calc(25);
        svg {
            margin-left: rem-calc(10);
            height: rem-calc(15);
            width: rem-calc(15);
        }
    }
    @include media(">=large") {
        text-align: left;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .close-cookie {
                margin-top: 0;
            }
        }
    }

    &[aria-hidden="true"] {
        transform: translate3d(0, 200%, 0);
    }
}

html.no-js#cookie-disclosure {
    display: none;
}
