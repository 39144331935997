.site-footer {
    flex-shrink: 0;
    text-align: center;
    z-index: 2;

    .container {
        @include grid-row;
        @include grid-single-col;
        row-gap: 0;
    }

    .footer-subscribe {
        background-color: $palette-background-dark;
        padding: rem-calc(48 0 39);

        .container {
            @include grid-single-col-full;
        }

        .subscribe-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            grid-column: colcentered;

            h2 {
                flex: 100%;
                max-width: rem-calc(257);
                margin: 0 auto rem-calc(40);
                @include font-size($h6-font-sizes);
                color: $palette-light-text;
                line-height: 1;
                text-align: center;
            }

            label {
                margin: 0;
            }
        }
    }

    .footer-main {
        position: relative;
        padding: rem-calc(40 0 50);
        background-color: $palette-background-med;
        z-index: 1;

        .footer-title {
            color: $palette-light-text;
        }
        .bg-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: multiply;
            z-index: -1;
            display: none;
        }

        .footer-logo {
            margin-bottom: rem-calc(25);
            a {
                display: block;
                width: 100%;
                height: rem-calc(125.5);
                margin: 0 auto;
                transition: transform 200ms ease-in;
                color: $white;

                svg {
                    object-fit: contain;
                    width: 100%;
                    height: 200px;
                    &.brand-icon {
                        // height: calc((64 / 125.5) * 100%);
                        margin-bottom: rem-calc(30);
                    }

                    // &.logomark {
                    //     height: calc((52 / 125.5) * 100%);
                    //     fill: $white;
                    // }
                }

                &:hover,
                &:focus {
                    transform: scale(1.02);
                }
            }
        }

        .nav-social {
            flex-wrap: nowrap;

            @include media("<medium") {
                margin-top: 105px;
            }
            @include media(">medium") {
                margin-top: 55px;
            }
            @include media(">large") {
                margin-top: 84px;
            }

            a {
                color: $white;

                &:hover,
                &:focus {
                    svg {
                        opacity: 0.7;
                    }
                }
            }
        }

        .footer-logos {
            padding: rem-calc(37 0);
            border-top: rem-calc(1) solid rgba($white, 0.3);
        }

        .footer-logos-wrapper {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            column-gap: rem-calc(16);
            row-gap: rem-calc(14);

            a {
                height: rem-calc(50);
                width: calc((100% - 32px) / 3);
                img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        .footer-links {
            padding: rem-calc(37 0);
            border-top: rem-calc(1) solid rgba($white, 0.3);

            // @include media("<medium") {
            //     padding: rem-calc(10 0);
            // }

            .footer-nav-wrapper {
                display: flex;
                flex-flow: column;
                justify-content: center;
                gap: rem-calc(16);
            }

            a {
                @include font-size($footer-nav-font-sizes);
                font-family: $typeface-global;
                font-weight: 400;
                letter-spacing: 0;
                color: $palette-secondary-light-text;
            }
        }

        .footer-info {
            padding-bottom: rem-calc(37);
            p,
            a:not(.social-link) {
                @include font-size($footer-font-sizes);
                color: $palette-secondary-light-text;
                text-transform: uppercase;
                font-family: $typeface-global;

                &[itemprop="address"] {
                    margin-bottom: rem-calc(16);
                }

                .telephone {
                    font-weight: $font-weight-bold;
                }
            }
        }

        .footer-meta {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: rem-calc(22);
            row-gap: rem-calc(16);
            padding-top: rem-calc(32);
            border-top: rem-calc(1) solid rgba($white, 0.3);

            @include media("<medium") {
                flex-flow: column;
                row-gap: 24px;
                padding-top: rem-calc(24);
            }

            &__address-copyright {
                flex: 1;
                @include font-size($label-xs);

                a {
                    @include font-size($label-s);
                    text-transform: uppercase;
                    color: $white;
                    font-family: $typeface-global;
                }

                p {
                    display: flex;
                    flex-flow: row nowrap;
                    margin-bottom: 0;
                    color: $palette-secondary-light-text;
                    @include font-size($label-s);
                    font-family: $typeface-global;
                }
            }

            .copyright,
            .copyright a {
                text-transform: uppercase;
                font-size: rem-calc(10);
                line-height: rem-calc(14);
                letter-spacing: 0.05em;
            }

            .copyright {
                span {
                    display: flex;

                    &:before {
                        display: block;
                        content: "|";
                        color: $white;
                        margin: 0 10px;
                    }

                    a {
                        margin-left: 3px;
                    }
                }
            }

            &__address {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row wrap;
                margin-bottom: 6px;

                @include media("<medium") {
                    flex-flow: column wrap;
                    margin-bottom: 24px;
                }

                .footer-meta__location {
                    display: flex;
                    margin-right: 0;
                    text-transform: uppercase;
                    padding-right: 5px;
                    &:after {
                        display: block;
                        content: "|";
                        color: $white;
                        margin: 0 10px;

                        @include media("<medium") {
                            display: none;
                        }
                    }
                }

                .phone-numbers {
                    display: flex;
                    margin-right: 0;
                    font-size: rem-calc(14);
                    text-transform: uppercase;
                    padding-right: 5px;
                    padding-bottom: 5px;
                    &:after {
                        display: block;
                        content: "|";
                        color: $white;
                        margin: 0 10px;

                        @include media("<medium") {
                            display: none;
                        }
                    }
                }
            }

            &__location {
                span {
                    margin-right: 2px;

                    &:first-of-type {
                        margin-right: 20px;
                    }
                }
            }

            .footer-image {
                display: block;
                width: rem-calc(93);
                height: rem-calc(38);

                svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            @include media("<420px") {
                p {
                    span {
                        flex: 100%;
                        margin: 0;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        h2 {
            @include font-size($footer-nav-font-sizes);
            font-family: $typeface-alt-header;
            text-transform: uppercase;
            color: $palette-primary-lighter-text;
            margin-bottom: rem-calc(21);
        }

        a {
            &:not(.footer-image):not(.social-link) {
                text-decoration: none;
                position: relative;
                @include animated-underline;

                &::after {
                    background-color: $white;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

        ul.footer-nav-wrapper {
            @extend .list-style-none;
        }

        [itemprop="addressLocality"] {
            &::after {
                content: ", ";
            }
        }
    }

    @include media("<small") {
        .footer-main h2 {
            color: $white;
        }

        .footer-subscribe .subscribe-container h2 {
            margin-bottom: rem-calc(32);
            text-transform: none;
            font-family: $typeface-global-bold;
        }
    }

    @include media(">=small") {
        .footer-subscribe {
            .subscribe-container {
                h2 {
                    text-transform: none;
                    font-family: $typeface-global-bold;
                    max-width: none;
                }
            }
        }

        .footer-logos-wrapper {
            margin: 0 auto;
            max-width: rem-calc(275);
        }
    }

    @include media(">=medium") {
        .container {
            grid-column-gap: $grid-gap;
        }
        .footer-main {
            padding: rem-calc(56 0 56);
            .container {
                @include grid-columns(3);
                grid-template-columns:
                    [col1-start] repeats(4, 1fr)
                    [col1-end col2-start] repeat(4, 1fr)
                    [col2-end col3-start] repeat(4, 1fr) [col3-end];
            }
            .footer-logo {
                padding: rem-calc(20 0);
            }
            .footer-links {
                // padding: rem-calc(67 0 46);
                border-top: 0;
            }
            .footer-logos {
                // padding-top: rem-calc(67);
                margin-left: auto;
                border-top: 0;
            }
            .footer-meta.grid-col:last-child {
                margin-top: rem-calc(40);
                justify-content: space-between;
                grid-column: 1 / span end;

                .nav-social {
                    display: flex;
                }
            }
            .footer-info {
                padding-bottom: 0;
            }
        }

        .footer-subscribe {
            padding: rem-calc(40 0);

            .subscribe-container {
                h2 {
                    font-family: $typeface-global-bold;
                    text-transform: none;
                    min-width: rem-calc(200);
                    margin: {
                        bottom: 0;
                        right: rem-calc(32);
                        color: $deep-black;
                    }
                    flex: 0 1 0;
                    text-align: left;
                }

                .subscribe-form {
                    flex: 1 0 0;
                    max-width: rem-calc(640);
                }
            }
        }
    }

    @include media(">=large") {
        .footer-subscribe {
            .container {
                @include grid-single-col-wide;
            }
            .subscribe-container {
                h2 {
                    min-width: rem-calc(257);
                    font-family: $typeface-global-bold;
                    text-transform: none;
                }
            }
        }
    }
    @include media(">=medium") {
        .footer-main {
            .container {
                grid-template-columns:
                    1fr [col1-start] repeat(3, 1fr)
                    [col1-end] 1fr [col2-start] repeat(3, 1fr)
                    [col2-end col3-start] repeat(4, 1fr) [col3-end] 1fr;
                .footer-meta.grid-col {
                    grid-column-start: col1;
                    grid-column-end: col3;
                }
            }
        }

        .footer-info {
            margin-left: rem-calc(-5);
            margin-right: rem-calc(-5);
        }
    }
}
