.m-things-to-do-listing {
    .things-to-do-listing-heading {
        @include line-decoration;
        &::before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .things-to-do-listing-heading,
    .things-to-do-listing-content {
        max-width: rem-calc(746);
        margin: 0 auto;
    }
    .things-to-do-listing-content {
        margin-top: rem-calc(41);
    }
    .listing-block {
        margin-top: rem-calc(50);
        background: $white;
        border: rem-calc(1) solid $palette-primary-darker;
        border-top-width: rem-calc(3);

        @include media(">=large") {
            margin-top: rem-calc(80);
        }
    }
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: rem-calc(24);
        margin: 0;
        padding: 0;
        @extend .list-style-none;

        &.js-carousel-mobile {
            // draggable carousel on mobile, same as jump-links
            overflow: hidden;

            &::after {
                content: "flickity";
                display: none;
            }
            .flickity-viewport {
                width: 100%;
                overflow: visible;
            }

            @include media(">=medium") {
                &::after {
                    content: ""; // remove carousel
                }
            }
        }

        a {
            &:focus {
                outline: 0;
                text-decoration: none;
            }
        }

        &.top-level {
            gap: rem-calc(56);
            height: rem-calc(66);
            border-bottom: rem-calc(1) solid $palette-primary-darker;

            li {
                padding: {
                    bottom: rem-calc(1);
                    right: rem-calc(56);
                }

                @include media(">=medium") {
                    padding-right: 0;
                }
            }

            a {
                font: {
                    family: $typeface-global;
                    size: rem-calc(12);
                }
                line-height: rem-calc(16);
                letter-spacing: 0.155em;

                &:hover,
                &:focus {
                    text-decoration: underline;
                    color: $black;
                }
            }
        }

        &.secondary-level {
            gap: rem-calc(32);
            height: rem-calc(45);
            background: $palette-light-emphasis;

            li {
                padding: {
                    bottom: rem-calc(3);
                    right: rem-calc(32);
                }

                @include media(">=medium") {
                    padding-right: 0;
                }
            }

            a {
                font-size: rem-calc(12);
                line-height: rem-calc(12);
                letter-spacing: 0.1em;
            }
        }
    }
    .secondary-level-content {
        margin: rem-calc(30 24 5);
        max-width: rem-calc(768);

        .posts-container {
            width: 100%;

            &[hidden] {
                display: none;
            }
        }

        h3 {
            position: relative;
            column-span: all;
            font-family: $typeface-header;
            text-transform: none;
            z-index: 1;
            margin-bottom: rem-calc(30);
            text-align: left;
            color: $black;

            @include media(">=500px") {
                text-align: center;
                span {
                    background-color: $white;
                    padding: rem-calc(0 32);
                }

                // &::before {
                //     content: "";
                //     height: 1px;
                //     width: 100%;
                //     position: absolute;
                //     left: 0;
                //     top: 50%;
                //     transform: translateY(-50%);
                //     background-color: $black;
                //     z-index: -1;
                // }
            }

            @include media(">=large") {
                font-size: rem-calc(42);
                line-height: rem-calc(48);
                margin-bottom: rem-calc(49);

                span {
                    padding: rem-calc(0 52);
                }
            }
        }

        .thing-to-do-post {
            width: 100%;
            display: flex;
            align-content: flex-start;
            flex-flow: row wrap;
            text-align: left;
            margin-bottom: rem-calc(32);

            .h7 {
                flex: 100%;
                margin-bottom: rem-calc(7);
                color: $black;
                letter-spacing: -0.02em;
                text-transform: none;
            }

            a {
                font-family: $typeface-global;
                line-height: rem-calc(28);
                text-decoration-color: $palette-primary;
                text-underline-offset: 0;

                + .address {
                    @include text-divider;
                    margin-left: rem-calc(17);
                    &::before {
                        content: "\2022";
                        color: $palette-text;
                        left: rem-calc(-12);
                    }
                }
            }

            .address {
                margin: 0;
            }

            .description {
                margin-top: rem-calc(8);
                font-size: rem-calc(14);
                line-height: rem-calc(24);
                color: $palette-primary;
            }

            .text-link {
                color: $palette-primary;
                font-size: rem-calc(12);

                .arrow-wrap {
                    height: 10px;
                    svg {
                        height: 10px;
                    }
                }
            }
        }

        @include media(">864px") {
            margin: {
                left: auto;
                right: auto;
            }
        }

        @include media(">=small") {
            .posts-container {
                column-count: 2;
            }
        }

        @include media(">=large") {
            margin: {
                top: rem-calc(47);
                bottom: rem-calc(12);
            }

            .posts-container {
                column-gap: rem-calc(64);
            }
        }
    }

    &.dining-menu {
        .secondary-level-content {
            h3 {
                &::before {
                    content: none;
                }
            }
            .posts-container {
                display: flex;
                flex-flow: row wrap;

                .section-title {
                    flex: 100%;
                    margin-bottom: rem-calc(24);

                    .h5 {
                        font-family: $typeface-alt-header;
                        font-style: italic;
                        text-transform: none;
                        letter-spacing: -0.02em;
                        position: relative;
                        z-index: 1;
                        color: $black;
                        text-align: left;

                        span {
                            background-color: $white;
                            padding-right: rem-calc(32);
                        }
                        &::before {
                            content: "";
                            height: 1px;
                            width: 100%;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: $palette-background-med;
                            z-index: -1;
                        }
                    }

                    .description {
                        max-width: rem-calc(480);
                        text-align: left;
                    }
                }

                .thing-to-do-post {
                    .h7 {
                        margin-bottom: 0;
                        flex: 0 0 calc(100% - 76px);
                    }

                    .price {
                        font-weight: $font-weight-medium;
                        flex: rem-calc(44);
                        text-align: right;
                        color: $black;
                    }

                    .description {
                        color: $palette-primary-darker;
                        margin-bottom: 0;
                        flex: 100%;
                    }

                    .wine-items {
                        margin-top: rem-calc(4);

                        p {
                            margin-bottom: 0;
                            line-height: 1.6;
                            span {
                                font-style: italic;
                            }
                        }
                    }
                }

                @include media(">=medium") {
                    column-gap: rem-calc(40);

                    .section-title {
                        .h5 {
                            text-align: center;

                            span {
                                padding-left: rem-calc(32);
                            }

                            &::before {
                                right: unset;
                                left: 0;
                            }
                        }
                        .description {
                            text-align: center;
                            margin: 0 auto;
                        }
                    }

                    .thing-to-do-post {
                        width: calc((100% - 40px) / 2);

                        > * {
                            flex: 0 0 calc(100% - 76px);
                        }
                    }
                }
            }

            @include media(">=medium") {
                margin-bottom: rem-calc(32);
            }

            @include media(">=large") {
                margin-bottom: rem-calc(47);
                .posts-container {
                    column-gap: rem-calc(64);

                    .thing-to-do-post {
                        width: calc((100% - 64px) / 2);
                    }
                }

                h3 {
                    margin-bottom: rem-calc(40);
                }
            }
        }
    }
}
