.m-video {
    .video-wrapper {
        @extend .button-style-none;
        position: relative;
        width: 100%;
        transition: transform $global-transition;

        &::before {
            content: "";
            display: block;
            width: 100%;
            padding-top: 56.25%;
        }

        img,
        .video-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
        }

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem-calc(67);
            height: rem-calc(67);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform $global-transition;
            background-color: rgba($black, 0.7);
            border: rem-calc(2) solid $white;
            border-radius: 50%;
            box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12);

            svg {
                fill: $white;
                width: rem-calc(26);
                height: rem-calc(25);
                margin-left: rem-calc(5);
                transition: transform $global-transition;
            }

            &:hover,
            &:focus {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
}
