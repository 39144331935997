// ===========================================================
// Container
// ===========================================================

@use "sass:math";
$page-padding: 27.5px;
$page-padding-double: $page-padding * 2;
$module-space-medium: math.div($module-space, 1.5);

%container {
    display: block;
    width: auto;
    margin-left: rem-calc(24);
    margin-right: rem-calc(24);

    @include media(">=#{$max-width+$page-padding-double}") {
        width: 100%;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
    }

    &.is-full-width {
        margin-left: 0;
        margin-right: 0;

        @include media(">#{$max-width}") {
            max-width: none;
        }
    }

    &.is-full-width-until-max {
        margin-left: 0;
        margin-right: 0;

        @include media(">=large") {
            margin-left: rem-calc(27.5);
            margin-right: rem-calc(27.5);
        }

        @include media(">=#{$max-width}") {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.container {
    @extend %container;
}
