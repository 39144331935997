.m-popup {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($black, 0.3);
	opacity: 0;
	transition: opacity 300ms ease-in;
	z-index: 301;

	.popup-content-wrapper {
		@include grid-columns;
		@include grid-row;
		align-items: flex-start;
		max-width: rem-calc(852);

		@include media(">=medium") {
			@include grid-two-symmetric-cols;
		}
	}

	.popup-copy-wrapper {
		text-align: center;
	}

	.popup-image-wrapper {
		display: none;
		height: 100%;

		img {
			height: 100%;
			object-fit: cover;
		}

		@include media(">=medium") {
			display: block;
		}
	}

	form {
		input[type="email"] {
			margin-bottom: rem-calc(14);
			text-align: center;

			&:active,
			&:focus,
			&:hover {
				padding-left: 0;
				background-color: transparent;
			}
		}
		.checkbox-group {
			padding: rem-calc(0 20);
		}
	}

	.checkbox-group {
		display: inline-flex;
		text-align: left;

		input {
			flex-shrink: 0;
			margin-top: rem-calc(3);
		}

		p,
		a {
			color: $palette-primary-lighter-text;
			font: {
				family: $typeface-header;
				size: rem-calc(12);
				weight: $font-weight-normal;
			}
			line-height: 2;
			letter-spacing: 0;
			text-transform: none;
		}

		a {
			color: $palette-dark;
		}
	}

	.popup-copy-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: rem-calc(35 30);
		background-color: $palette-primary-lighter;

		.heading-eyebrow {
			margin-bottom: rem-calc(18);
		}

		h2 {
			padding-bottom: rem-calc(12);
		}

		.popup-buttons {
			&.button-group {
				justify-content: center;
				margin-left: 0;
			}

			a {
				min-width: rem-calc(172);
				padding: rem-calc(0 30);
			}
		}

		@media screen and (max-height: 575px) {
			max-height: 100%;
			overflow: auto;
		}

		@include media(">=large") {
			padding: rem-calc(40 60);
		}

		@media screen and (min-width: 1024px) and (max-height: 800px) {
			h2,
			.h2 {
				font-size: rem-calc(28);
			}
			p {
				font-size: rem-calc(14);
			}
		}

		.close-popup {
			position: absolute;
			right: rem-calc(10);
			top: rem-calc(9);
			width: rem-calc(45);
			min-width: rem-calc(45);
			height: rem-calc(45);
			padding: 0;
			background-color: $palette-primary-lighter;
			border: none;
			z-index: 2;
			cursor: pointer;

			&::before,
			&::after {
				content: " ";
				position: absolute;
				width: rem-calc(2);
				height: rem-calc(34);
				transition: opacity 0.3s ease;
				background-color: $palette-primary;
			}
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}

			&:hover,
			&:focus {
				&::before,
				&::after {
					opacity: 0.6;
				}
			}

			&:focus {
				border-color: $palette-primary;
			}

			span {
				color: $black;
			}
		}
	}

	.popup-content-wrapper.container {
		min-height: rem-calc(426);
		grid-column-gap: 0;
		overflow: hidden;
		opacity: 0;
		transform: translateY(15px);
		transition: transform 300ms ease-in, opacity 300ms linear;
	}

	&[aria-hidden="false"] {
		opacity: 1;

		.popup-content-wrapper.container {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
