.jump-links {
    width: 100%;
    padding: rem-calc(24);
    display: flex;
    justify-content: center;
    background-color: none;
    margin-bottom: rem-calc(32);

    &::after {
        content: "flickity";
        display: none;
    }

    a.button-tab {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: rem-calc(24);
        min-width: max-content;
        color: $white;
        font-size: rem-calc(24);
        transition: font 300ms ease-in;

        &::after {
            background-color: $white;
        }

        &:hover,
        &:focus {
            color: $white;
        }

        &:not(:first-child) {
            margin-left: rem-calc(48);

            &::before {
                content: "";
                background-color: rgba($white, 0.1);
                position: absolute;
                width: rem-calc(1);
                height: 100%;
                top: 50%;
                left: -24px;
                transform: translateY(-50%);
            }
        }
    }

    .flickity-viewport {
        width: 100%;
        overflow: visible;
    }

    @include media(">=medium") {
        &::after {
            content: ""; // remove carousel
        }
        padding: rem-calc(24 0);
    }

    @include media(">=large") {
        margin-bottom: rem-calc(88);

        a {
            &:not(:first-child) {
                margin-left: rem-calc(64);
            }
        }
    }
}

// offset fixed header
[id] {
    scroll-margin-top: 80px;
}
