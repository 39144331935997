.m-welcome-text {
    position: relative;
    text-align: center;
    z-index: 3; // O glyph should overlap next module on mobile only

    @include media(">=medium") {
        z-index: 2;
    }

    &.section-container:nth-child(3) {
        padding-top: rem-calc(105);

        @include media(">=medium") {
            padding: {
                top: rem-calc(200);
                bottom: rem-calc(100);
            }
        }
        @include media(">=large") {
            padding: {
                top: rem-calc(290);
                bottom: rem-calc(170);
            }
        }
    }

    .brand-glyph,
    .welcome {
        pointer-events: none;
        position: absolute;
        color: $white;
        transform: translateX(-50%);

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .welcome-text-heading {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .welcome-text-title {
            position: relative;

            h2 {
                margin-bottom: 0;
            }
        }
        .subtitle {
            margin-top: rem-calc(25);
            max-width: rem-calc(236);

            @include media(">=medium") {
                margin-top: rem-calc(16);
                max-width: none;
            }
        }
    }

    .lead {
        max-width: rem-calc(480);
        padding-top: rem-calc(29);
    }

    // The "welcome" text
    .welcome {
        position: absolute;
        top: rem-calc(-80);
        left: 50%;
        // height: max-content;
        width: 99vw;
        max-width: rem-calc(550);
        transform: translateX(-50%);
        z-index: -1;

        @include media(">=medium") {
            top: rem-calc(-95);
            max-width: none;
            width: 75vw;
            height: 285%;
        }

        @include media(">=large") {
            top: rem-calc(-164);
            width: 71vw;
        }
    }

    // The big "0"
    .brand-glyph {
        top: 50%;
        left: 50%;
        height: 200%;
        width: 103vw;
        opacity: 0.3;
        transform: translate(-50%, -50%);
        z-index: -1;

        @include media(">=medium") {
            height: 150%;
        }

        @include media(">=large") {
            height: 165%;
            width: 66%;
            max-width: rem-calc(800);
        }
    }
}
