// ===========================================================
// Navigation Overlay
// ===========================================================

.overlay-nav-open {
    .logomark {
        display: none;
        @include media(">=large") {
            display: block;
        }
    }
    .logomark-dark {
        display: none;
    }
    .wc-logo-white {
        //logo in mobile open nav
        display: block;
        width: rem-calc(800);
        height: rem-calc(1800);
        @include media(">=large") {
            display: none;
        }
    }

    .main-nav-logo {
        color: $white;
    }
    .menu-toggle {
        .icon span {
            background-color: $white;
        }
    }
}

.site-overlay-nav {
    .overlay-nav-item {
        .sub-nav {
            font-weight: $font-weight-normal;
        }
    }

    .main-menu {
        position: relative;
        z-index: 1;
    }

    .overlay-nav-item-supporting,
    .overlay-contact-item {
        a,
        p {
            padding: rem-calc(10 0);
            font: {
                family: $typeface-global;
                size: rem-calc(12);
                weight: $font-weight-bold;
            }
            text-transform: uppercase;

            @include media(">=large") {
                color: $white;
            }
        }
        a {
            position: relative;
            @include animated-underline;

            &::after {
                background-color: $white;
                bottom: rem-calc(5);
            }
            &:focus,
            &:hover {
                color: $white;
            }
        }
    }

    .overlay-nav-cta {
        border: 0;
        a {
            display: flex;
            color: $white;
        }
    }

    .bg-image {
        display: none;
    }

    @include media(">=medium") {
        padding-top: rem-calc(140);
    }

    @include media(">=large") {
        padding-top: 0;

        .bg-image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            mix-blend-mode: multiply;
            z-index: -1;
            opacity: 0.2;
        }

        .overlay-nav-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            max-width: rem-calc(1440);
            margin: 0 auto;
        }

        .overlay-nav-wrapper {
            height: 100%;
            padding-top: rem-calc(112);
            overflow-y: auto;
        }

        ul {
            display: flex;
            flex-direction: column;
            width: max-content;
            padding: {
                right: rem-calc(100);
                left: rem-calc(100);
            }
        }

        .overlay-nav-cta {
            display: none;
        }
    }

    @include media(">=xlarge") {
        ul {
            padding-left: rem-calc(160);
        }
    }

    // State - Open
    &[data-menu="open"] {
        display: block;
    }
}
