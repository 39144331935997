.map-module.section-container {
    padding-bottom: 0;

    &:not(:last-child) {
        margin-bottom: rem-calc(180);
    }

    &:last-child {
        background: $palette-primary;
        padding-bottom: rem-calc(110);
        .info-box {
            bottom: rem-calc(40);
        }
        @include media(">=large") {
            padding-bottom: rem-calc(155);
            .info-box {
                bottom: rem-calc(72);
            }
        }
    }
    #contact-map {
        height: rem-calc(561);
    }

    .latlng {
        display: none;
    }

    .info-box {
        position: absolute;
        bottom: rem-calc(-99);
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 48px);
        max-width: rem-calc(544);
        background: $white;
        padding: rem-calc(24 24 32);
        z-index: 1;
        text-align: center;

        span {
            font-size: rem-calc(20);
            line-height: rem-calc(32);
        }

        .button {
            margin-top: rem-calc(24);
        }

        [itemprop="streetAddress"],
        [itemprop="addressRegion"],
        [itemprop="postalCode"] {
            &::after {
                content: ", ";
            }
        }

        @include media(">=large") {
            bottom: rem-calc(-83);
            left: 11%;
            transform: none;
            padding: rem-calc(96);
            text-align: left;

            .button {
                margin-top: rem-calc(44);
            }
        }
    }
}
