// ===========================================================
// Image Gallery
// ===========================================================

@use "sass:math";

.m-image-gallery {
  .image-gallery-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .col-image {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: math.div($module-space, 4);
    width: 100%;

    figcaption {
      align-self: flex-end;
      padding: rem-calc(56 0 28);
      color: $palette-primary-lighter;
      margin: 0;
      text-align: center;
    }
  }

  @include media(">=medium") {
    .image-gallery-row {
      margin-left: math.div($grid-gap-horizontal, -2);
      margin-right: math.div($grid-gap-horizontal, -2);
    }

    .col-image {
      flex: 0 0 50%;
      width: 50%;
      border-left: solid math.div($grid-gap-horizontal, 2) transparent;
      border-right: solid math.div($grid-gap-horizontal, 2) transparent;
    }
  }

  @include media(">=large") {
    .col-image {
      flex: 0 0 25%;
      width: 25%;
      margin-bottom: math.div($module-space, 3);
    }

    &.three-up-cols {
      .col-image {
        flex: 0 0 33.3%;
        width: 33.3%;
      }
    }

    &.two-up-cols {
      .col-image {
        flex: 0 0 50%;
        width: 50%;
      }
    }
  }
}
